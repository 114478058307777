import { HttpClient } from '@store/base';
import {
  GetGuestInfoResponse,
  LoginRequest,
  LogoutRequest,
  RefreshTokenRequest,
  RefreshTokenResponse,
} from '../../model';
import queryString from 'query-string';

export class LoginGateway {
  constructor(readonly loginUrl: string) {}

  async exec({ returnUrl }: LoginRequest) {
    const query = queryString.stringify({ returnUrl });
    return `${this.loginUrl}?${query}`;
  }
}

export class LogoutGateway {
  async exec({ returnUrl }: LogoutRequest) {
    const query = queryString.stringify({ returnUrl });
    return `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/customer/logoutOuter.do?${query}`;
  }
}

export class RefreshTokenGateway {
  constructor(readonly httpClient: HttpClient) {}

  async exec(req: RefreshTokenRequest) {
    return await this.httpClient.request<RefreshTokenResponse>({
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_BASE_API_HOST}/hanssem/hanssem-mall-service/API/v4/auth/reissueToken `,
      body: req,
      headers: {
        SiteKey: `${process.env.NEXT_PUBLIC_HANSSEMMALL_SITE_KEY}`,
        'User-Agent': 'axios/nextjs/RemodelingMobileWeb, VDT',
      },
    });
  }
}

export class GetGuestInfoGateway {
  constructor(readonly httpClient: HttpClient) {}

  async exec() {
    return await this.httpClient.request<GetGuestInfoResponse>({
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_BASE_API_HOST}/hanssem/common-service/api/v1/commons/customers/identity-number`,
    });
  }
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../store';
import { HYDRATE } from 'next-redux-wrapper';

type VariationDetailValue = {
  variation: string;
  deviceId: string;
};

type VariationDetail = Record<string, VariationDetailValue> | null;

// Define a type for the slice state
interface HackleState {
  variationDetail: VariationDetail;
}

// Define the initial state using that type
const initialState: HackleState = {
  variationDetail: null,
};

export const hackleSlice = createSlice({
  name: 'hackle',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateVariationDetail: (state, action: PayloadAction<VariationDetail>) => {
      state.variationDetail = action.payload;
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.hackle,
      };
    },
  },
});

export const { updateVariationDetail } = hackleSlice.actions;

// Other code such as selectors can use the imported `RootState`
export const selectVariationDetail = (state: RootState) =>
  state.hackle.variationDetail;

export default hackleSlice;

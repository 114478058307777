import { TransactionEvent, EventHint } from '@sentry/types';
import axios from 'axios';

const JANDI_CONNECT_URL =
  'https://wh.jandi.com/connect-api/webhook/24103837/9d29c59d1604a2b28a1ed80c862b0760';

class SentryWebhook {
  constructor() {}
  send(event: TransactionEvent, hint: EventHint) {
    if (!event.level) return event;

    const message = {
      body: `[[센트리 알림: ${event.level?.toUpperCase()}]] (https://hanssem.sentry.io/issues/${
        event.event_id
      })`,
      connectInfo: [
        {
          title: '도메인',
          description: process.env.NEXT_PUBLIC_WEB_HOST,
        },
        {
          title: '환경',
          description: process.env.NEXT_PUBLIC_STAGE,
        },
        {
          title: '요청URL',
          description: event.request && event.request.url,
        },
        {
          title: '오류타입',
          description:
            event.exception?.values?.[0] && event.exception?.values[0].type,
        },
        {
          title: '오류상세',
          description:
            event.exception?.values?.[0] && event.exception?.values[0].value,
        },
        {
          title: 'Headers',
          description: event.request && JSON.stringify(event.request.headers),
        },
        {
          title: 'Tags',
          description: JSON.stringify(event.tags),
        },
      ],
    };

    axios({
      method: 'POST',
      url: JANDI_CONNECT_URL,
      headers: {
        Accept: 'application/vnd.tosslab.jandi-v2+json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
      data: message,
    }).then(() => {
      console.log(
        'Error logged!',
        hint.originalException || hint.syntheticException,
      );
    });

    return event;
  }
}

export default SentryWebhook;

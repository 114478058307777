import { theme } from './theme';
import { css } from 'styled-components';
export const CSS = {
  dividerGray: '#D9D9D9',
  center: {
    display: 'flex',
    'justify-content': 'center',
    'align-items': 'center',
  },
};

export const CustomScroll = css`
  overflow: overlay;
  ::-webkit-scrollbar {
    width: 16px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    width: 4px;
    box-shadow: inset 6px 6px ${theme.colors.neutral_colors.gray[200]};
    outline: none;
    border: 6px solid transparent;
    border-radius: 12px;
  }
`;

import '@/styles/globals.css';
import 'react-virtualized/styles.css';

import * as Sentry from '@sentry/nextjs';

import {
  AlertProvider,
  ConfirmProvider,
  Theme as HSTheme,
  ThemeProvider,
  ToastProvider,
} from '@design-system/pc';
import { DefaultSeo, NextSeo } from 'next-seo';
import { NextApiRequest, NextApiResponse, NextPage } from 'next';
import React, { ReactElement, ReactNode, useState } from 'react';
import { SagaStore, rootAppWrapper, wrapper } from '../src/redux/store';

import App from 'next/app';
import type { AppProps } from 'next/app';
import LoadingModalProvider from '@/providers/LoadingModalProvider';
import ImageDetailModalProvider from '@/providers/ImageDetailModalProvider';
import { DisplayComponentProvider } from '@display-component/pc';
import { END } from 'redux-saga';
import ModalProvider from '@/providers/ModalProvider';
import NCookieStorage from '@/services/ncookie';
import { PopupProvider } from '@/providers/PopupProvider';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import SEO from 'seo.config';
import { SWRConfig } from 'swr';
import { ServerResponse } from 'http';
import TrackingService from '@/services/tracking';
import WebProvider from '@/providers/WebProvider';
import { authGateway } from '@/di-container/auth/gateway/server';
import { axios } from '@/di-container/axios';
import useAuthenticated from '@/hooks/useAuthenticated';
import DrawerPopUpProvider from '@/providers/DrawerProvider';
import ShareProvider from '@/providers/ShareProvider';
import BrazeProvider from '@/providers/BrazeProvider';
import { createInstance, HackleProvider, User } from '@hackler/react-sdk';
import { getClientUser } from '@/utils/hackle';
import { useRouter } from 'next/router';
import NextHead from 'next/head';

if (process.env.NEXT_PUBLIC_MOCK_ENABLED === 'enabled') {
  import('@/mocks');
}

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

export type DefaultAppProps = AppProps & {
  Component: NextPageWithLayout;
};

export const hackleClient = createInstance(
  `${process.env.NEXT_PUBLIC_HACKLE_API_KEY}`,
  { exposureEventDedupIntervalMillis: 1000 },
);

if (typeof window !== 'undefined') {
  // @ts-ignore
  window.hackleClient = hackleClient;
}

function MyApp({ Component, pageProps }: DefaultAppProps) {
  const router = useRouter();
  const { isAuthenticated, guestInfo } = useAuthenticated();
  const [hackleUser, setHackleUser] = useState<User>();

  React.useEffect(() => {
    console.log('stage : ', process.env.NEXT_PUBLIC_STAGE);
    setHackleUser(getClientUser());

    Sentry.configureScope((scope) => {
      const userInfo = isAuthenticated
        ? { state: 'AUTH' }
        : { state: 'GUEST', ...guestInfo };
      scope.setUser({ ...userInfo });
    });

    return () => {
      Sentry.configureScope((scope: Sentry.Scope) => scope.clear());
    };
  }, [guestInfo, isAuthenticated]);

  const hosts = {
    hanssemApiGateway: process.env.NEXT_PUBLIC_BASE_API_HOST || '',
    remodelingAsset: process.env.NEXT_PUBLIC_ASSET_HOST || '',
    hanssemMallCDN: process.env.NEXT_PUBLIC_HANSSEMMALL_CDN_HOST || '',
  };

  return (
    <>
      <NextHead>
        <link
          rel="alternate"
          media="only screen and (max-width: 640px)"
          href={process.env.NEXT_PUBLIC_MWEB_HOST + router.asPath}
        />
        <link
          rel="canonical"
          href={process.env.NEXT_PUBLIC_WEB_HOST + router.asPath}
        />
      </NextHead>
      <DefaultSeo {...SEO} />
      {process.env.NEXT_PUBLIC_STAGE !== 'production' && (
        <NextSeo noindex nofollow />
      )}
      <SWRConfig
        value={{
          revalidateOnFocus: false,
          revalidateOnReconnect: false,
        }}
      >
        <ThemeProvider theme={{} as HSTheme}>
          {(theme) => (
            <SCThemeProvider theme={theme!}>
              <BrazeProvider>
                <HackleProvider
                  hackleClient={hackleClient}
                  supportSSR
                  user={hackleUser}
                >
                  <DisplayComponentProvider
                    hosts={hosts}
                    httpClient={axios}
                    tagGTM={TrackingService.tagGTM}
                  >
                    <WebProvider>
                      <ToastProvider>
                        <LoadingModalProvider>
                          <ImageDetailModalProvider>
                            <ModalProvider>
                              <PopupProvider>
                                <ShareProvider>
                                  <DialogProvider>
                                    <DrawerPopUpProvider>
                                      <Component {...pageProps} />
                                    </DrawerPopUpProvider>
                                  </DialogProvider>
                                </ShareProvider>
                              </PopupProvider>
                            </ModalProvider>
                          </ImageDetailModalProvider>
                        </LoadingModalProvider>
                      </ToastProvider>
                    </WebProvider>
                  </DisplayComponentProvider>
                </HackleProvider>
              </BrazeProvider>
            </SCThemeProvider>
          )}
        </ThemeProvider>
      </SWRConfig>
    </>
  );
}

const DialogProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <AlertProvider>
      {() => <ConfirmProvider>{() => children}</ConfirmProvider>}
    </AlertProvider>
  );
};

MyApp.getInitialProps = wrapper.getInitialAppProps(
  (store) => async (context) => {
    const pageProps = (await App.getInitialProps(context)).pageProps;

    const gateway = authGateway(
      context.ctx.req as NextApiRequest,
      context.ctx.res as NextApiResponse,
      store.dispatch,
    );
    await gateway.syncToken.exec();
    await gateway.syncGuest.exec();

    axios.refreshTokenGateway = gateway.refreshToken;

    store.dispatch(END);
    await (store as unknown as SagaStore).sagaTask?.toPromise();

    await new TrackingService(
      new NCookieStorage(
        context.ctx.req as NextApiRequest,
        context.ctx.res as NextApiResponse,
      ),
      store.dispatch,
      store.getState(),
    ).init();

    setTimeout(() => {
      const res = context.ctx.res as ServerResponse;

      if (!res.headersSent) {
        res.writeHead(302, { Location: '/timeout' }).end().req.destroy();
      }
    }, 55000);

    return {
      pageProps: {
        ...pageProps,
        initialState: store.getState(),
      },
    };
  },
);

export default rootAppWrapper(MyApp);

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  selectAuthInfo,
  selectGuestInfo,
  selectIsAuthenticated,
  selectIsGuest,
} from '../redux/reducers/auth';

function useAuthenticated() {
  const authInfo = useSelector(selectAuthInfo);
  const guestInfo = useSelector(selectGuestInfo);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isGuest = useSelector(selectIsGuest);
  const custNo = useMemo(() => {
    if (isAuthenticated && authInfo) return authInfo?.custNo;
    if (isGuest && guestInfo) return guestInfo?.custNo;
  }, [authInfo, guestInfo, isAuthenticated, isGuest]);

  return { isAuthenticated, authInfo, isGuest, guestInfo, custNo };
}
export default useAuthenticated;

import React, { ReactNode, useEffect, useRef } from 'react';
import { HStack, Text } from '@design-system/pc';
import Image from '@/ui/Image';
import styled, { css } from 'styled-components';
import { getAsset } from '@/utils';
import { CustomScroll } from '@/styles/common';
import { POPUP_Z_INDEX, theme } from '@/styles';
import TrackingService from '@/services/tracking';

type PopupSizeType = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
type BtnType = 'oneBtn' | 'twoBtn' | 'noneBtn';

export interface PopupProps {
  title?: string;
  titleAlign?: 'left' | 'center';
  isOpen: boolean;
  popupSize?: PopupSizeType;
  onClose: (e: boolean) => void;
  buttonType?: BtnType;
  children?: ReactNode;
  FooterChildren?: ReactNode;
  closeBtnText?: string;
  btnDisabled?: boolean;
  confirmBtnText?: string;
  scrollControl?: boolean;
  gtmName?: string;
  gtmValue?: Record<string, any>;
}

const PopupCloseIcon = '/assets/icons/ic_close_48.svg';

const Popup = ({
  titleAlign = 'left',
  scrollControl = true,
  gtmName = '',
  gtmValue,
  ...props
}: PopupProps) => {
  const childrenRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // 팝업 오픈 시 children의 스크롤 위치를 상단으로 초기화하는 effect
    if (!childrenRef.current) return;
    childrenRef.current.scrollTo(0, 0);
  });

  useEffect(() => {
    if (!props.isOpen) return;
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [props.isOpen]);

  return (
    <DimmedBox isOpen={props.isOpen} {...TrackingService.tagGTM(`${gtmName}`)}>
      <PopupBox size={props.popupSize || 'sm'} scrollControl={scrollControl}>
        <HStack
          m={
            props.popupSize !== 'xs'
              ? '16px 28px 0px 28px'
              : '16px 16px 0px 16px'
          }
          pb={props.title ? '8px' : '0px'}
          borderBottom={props.title ? '2px solid' : 'none'}
          borderBottomColor={props.title ? 'color_chip.primary' : ''}
          alignment="center"
          space="space-between"
        >
          {titleAlign === 'center' && <EmptyBox width="48px" />}
          <Text variant="headline2">{props.title}</Text>
          <IconBox
            onClick={() => props.onClose(false)}
            {...TrackingService.tagGTM(
              gtmName ? `${gtmName}_close_button` : 'close_button',
              gtmValue,
            )}
          >
            <Image
              src={getAsset(PopupCloseIcon)}
              width="48px"
              height={'48px'}
              alt="popupCloseIcon"
            />
          </IconBox>
        </HStack>
        <PopupContent
          isTitle={Boolean(props.title)}
          ref={childrenRef}
          scrollControl={scrollControl}
        >
          {props.children}
        </PopupContent>

        {props.FooterChildren && (
          <PopupFooterChildren>{props.FooterChildren}</PopupFooterChildren>
        )}
      </PopupBox>
    </DimmedBox>
  );
};

export default Popup;

const DimmedBox = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  position: fixed;
  z-index: ${POPUP_Z_INDEX};
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.accent_colors.dimmed[1]};
`;

const sizes = {
  xs: {
    width: '320px',
  },
  sm: {
    width: '370px',
  },
  md: {
    width: '570px',
  },
  lg: {
    width: '770px',
  },
  xl: {
    width: '970px',
  },
  xxl: {
    width: '1170px',
  },
};

const PopupBox = styled.div<{ size: PopupSizeType; scrollControl: boolean }>`
  background-color: ${theme.colors.neutral_colors.white};
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-height: ${({ scrollControl }) =>
    scrollControl ? '700px' : 'fit-content'};
  ${({ size }) =>
    css`
      ${sizes[size]}
    `};
`;

const PopupContent = styled.div<{ isTitle: boolean; scrollControl: boolean }>`
  position: relative;
  flex: 1;
  padding-top: ${({ isTitle }) => (isTitle ? '24px' : '0px')};
  /* 마진 바텀 40px 주면 모든 컨텐츠에서 스크롤이 생김.. 확인필요 */
  /* padding-bottom: 2px;*/
  /* margin-bottom: 38px; */
  height: fit-content;
  box-sizing: content-box;
  overflow: ${({ scrollControl }) => (scrollControl ? 'auto' : 'visible')};
  ${({ scrollControl }) => (scrollControl ? CustomScroll : {})};
`;

const IconBox = styled.div`
  cursor: pointer;
`;

const PopupFooterChildren = styled.div`
  width: 100%;
`;

const EmptyBox = styled.div<{ width: string }>`
  width: ${({ width }) => width};
`;

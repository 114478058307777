import { NextApiRequest, NextApiResponse } from 'next';
import { AnyAction, Dispatch } from 'redux';
import {
  GPS_LATITUDE_MIN,
  GPS_LATITUDE_MAX,
  GPS_LONGITUDE_MIN,
  GPS_LONGITUDE_MAX,
  GPS_DEFAULT_LATITUDE,
  GPS_DEFAULT_LONGITUDE,
} from '@/constants';

class WebAppService {
  constructor(
    private readonly req?: NextApiRequest,
    private readonly res?: NextApiResponse,
    private readonly dispatch?: Dispatch<AnyAction>,
  ) {}

  static filterLocation({
    latitude,
    longitude,
  }: {
    latitude: number;
    longitude: number;
  }) {
    if (
      latitude > GPS_LATITUDE_MIN &&
      latitude < GPS_LATITUDE_MAX &&
      longitude > GPS_LONGITUDE_MIN &&
      longitude < GPS_LONGITUDE_MAX
    ) {
      return { latitude, longitude };
    } else {
      return {
        latitude: GPS_DEFAULT_LATITUDE,
        longitude: GPS_DEFAULT_LONGITUDE,
      };
    }
  }
}

export default WebAppService;

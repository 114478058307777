import _ from 'lodash';
import { GoodsOptionNode, GoodsOptionList } from '@/types';

export const seekOptionByPath = (
  tree: GoodsOptionNode[],
  path: number[],
): GoodsOptionNode | null => {
  let found: GoodsOptionNode | null = null;

  if (path.length < 1) {
    return found;
  }

  let list = tree;
  path.forEach((artiSeq, index) => {
    const it = _.find(list, { artiSeq });
    if (it) {
      if (it.nextOptionList) {
        list = it.nextOptionList;
      }
      if (index === path.length - 1) {
        found = it;
      }
    }
  });

  return found;
};

export const getEachNodesByPath = (tree: GoodsOptionNode[], path: number[]) => {
  const nodes: GoodsOptionNode[] = [];

  path.forEach((_, index) => {
    const currentPath = path.slice(0, index + 1);
    const currentNode = seekOptionByPath(tree, currentPath);

    if (currentNode) {
      nodes.push(currentNode);
    }
  });

  return nodes.length !== path.length ? null : nodes;
};

export const getDropdownListByPath = (
  tree: GoodsOptionNode[],
  list: GoodsOptionList,
  path: number[],
): { placeholder: string; items: GoodsOptionNode[] }[] => {
  return list.map((item, index) => {
    let items: GoodsOptionNode[] = [];

    if (index === 0) {
      items = tree;
    } else {
      items =
        seekOptionByPath(tree, path.slice(0, index))?.nextOptionList || [];
    }

    return {
      placeholder: item.titleNm,
      items,
    };
  });
};

import { getAsset } from '@/utils';
import React from 'react';
import styled from 'styled-components';

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  width: string | number;
  height: string | number;
  src: string;
  objectFit?: 'contain' | 'full' | 'cover';
  imgState?: 'circle' | 'square';
}

const Image = ({ imgState = 'square', ...props }: ImageProps) => {
  return (
    <Container width={props.width} height={props.height}>
      <ImageBox
        width={props.width}
        height={props.height}
        src={props.src}
        imgState={imgState}
        objectFit={props.objectFit}
        {...{ props }}
        onError={({ currentTarget }) => {
          currentTarget.src = getAsset('/assets/images/no_image.png');
        }}
      />
    </Container>
  );
};

export default Image;

const Container = styled.div<{
  width: string | number;
  height: string | number;
}>`
  display: flex;
  align-items: center;
  justify-items: center;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

const ImageBox = styled.img<{
  objectFit?: 'contain' | 'full' | 'cover';
  imgState: 'circle' | 'square';
}>`
  object-fit: ${({ objectFit }) => objectFit || 'contain'};
  object-position: center;
  border-radius: ${({ imgState }) => (imgState === 'circle' ? '50%' : '0% ')};
`;

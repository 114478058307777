export const USER_AGENT_AOS_REG_EX = /HSAppAOS/;
export const USER_AGENT_IOS_REG_EX = /HSAppIOS/;

export const WEBAPP_TYPE_WEB = 'WEB';
export const WEBAPP_TYPE_AOS = 'AOS';
export const WEBAPP_TYPE_IOS = 'IOS';

export const GPS_LATITUDE_MIN = 32.83211;
export const GPS_LATITUDE_MAX = 37.58715;
export const GPS_LONGITUDE_MIN = 124.28821;
export const GPS_LONGITUDE_MAX = 132.79321;
export const GPS_DEFAULT_LATITUDE = 37.576162;
export const GPS_DEFAULT_LONGITUDE = 126.898761;

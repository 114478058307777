import { HttpClient, RequestOption } from '@store/base';
import { SagaStore } from '@/redux/store';
import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import queryString from 'query-string';
import * as Sentry from '@sentry/nextjs';

abstract class BaseAxios implements HttpClient {
  protected instance: AxiosInstance;
  protected requestInterceptorSeq: number = 0;
  protected responseInterceptorSeq: number = 0;

  constructor(protected readonly config?: AxiosRequestConfig) {
    this.instance = this.createInstance(config);
  }

  syncStore(store: SagaStore) {
    this.setInterceptor(store);
  }

  protected setInterceptor(store: SagaStore) {
    this.instance.interceptors.request.eject(this.requestInterceptorSeq);
    this.instance.interceptors.response.eject(this.responseInterceptorSeq);
    this.requestInterceptor(store);
    this.responseInterceptor(store);
  }

  private createInstance(config?: AxiosRequestConfig): AxiosInstance {
    const timeout = 60 * 1000; // 60초
    return axios.create({
      headers: {
        'Content-Type': 'application/json',
      },
      timeout,
      ...config,
    });
  }

  protected abstract requestInterceptor(store: SagaStore): void;

  protected abstract responseInterceptor(store: SagaStore): void;

  request<Response>({
    method,
    url,
    headers,
    body,
  }: RequestOption): Promise<Response> {
    return this.instance({
      method,
      url,
      headers,
      data: body,
      params: method.toUpperCase() === 'GET' && body,
      paramsSerializer: (params) =>
        queryString.stringify(params, { arrayFormat: 'comma' }),
    })
      .then(
        (response) => <Response>(<unknown>{
            ...response.data,
          }),
      )
      .catch((error) => {
        console.error(`axios error : ${error}`);
        const response = {
          code: error.response?.status || 500,
          message: error?.message || '잠시후 다시 시도해 주세요.',
        };

        if (error.code === 'ECONNABORTED') {
          response.message =
            '브라우저가 강제로 멈추었습니다.\n다시 시도해 주세요.';
        }

        Sentry.withScope(function (scope) {
          scope.setTag('type', 'API');
          scope.setLevel('error');
          Sentry.setContext('API Request Detail', {
            method,
            url,
            headers: error.config?.headers,
          });

          Sentry.setContext('API Response Detail', {
            ...response,
            headers: error.response?.headers || [],
          });

          Sentry.captureException(
            new Error(`axios error occurred : [${method}]${url}`),
          );
        });
        return <Response>(<unknown>response);
      });
  }
}

export default BaseAxios;

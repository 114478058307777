import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { CategoryItem } from '@store/goods';
import { RootState } from '../store';

// Define a type for the slice state
export interface GoodsCategoryState {
  categoryTree: CategoryItem[];
}

// Define the initial state using that type
const initialState: GoodsCategoryState = {
  categoryTree: [],
};

export const goodsCategorySlice = createSlice({
  name: 'goodsCategory',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setCategoryTree: (state, action: PayloadAction<CategoryItem[]>) => {
      state.categoryTree = action.payload;
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.goodsCategory,
      };
    },
  },
});

export const {
  setCategoryTree,
  // setCategoryNow
} = goodsCategorySlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCategoryTree = (state: RootState) =>
  state.goodsCategory.categoryTree;

export default goodsCategorySlice;

import WebAppService from '@/services/webapp';
import { GetLocationData, WebAppMessage } from '@/types';
import React, { createContext, useCallback, useContext } from 'react';

export type GetLocationCallback = (
  message: WebAppMessage<GetLocationData>,
) => Promise<void>;

interface WebContextType {
  handleGetLocation: (
    callbackId: string | null,
    callback: GetLocationCallback,
  ) => void;
}

const WebContext = createContext<WebContextType>({
  handleGetLocation: () => {},
});

export function useWeb() {
  const context = useContext(WebContext);
  if (context === undefined) {
    throw new Error('useWeb must be used within a webContext');
  }
  return context;
}

function WebProvider({ children }: { children?: React.ReactNode }) {
  const messageSeq = 1;
  const handleGetLocation = useCallback(
    (
      callbackId: string | null,
      callback: (message: WebAppMessage<GetLocationData>) => Promise<void>,
    ) => {
      const message: WebAppMessage<GetLocationData> = {
        _id: messageSeq,
        action: 'getLocation',
      };

      if (window.navigator && window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          (result) => {
            const { latitude, longitude } = WebAppService.filterLocation(
              result.coords,
            );
            message.code = 200;
            message.message = 'OK';
            message.data = {
              from: 'navigator',
              latitude,
              longitude,
            };
            callback(message);
          },
          (error) => {
            message.code = 900 + error.code;
            message.message = error.message;
            callback(message);
          },
          {
            timeout: 30 * 1000,
          },
        );
      } else {
        message.code = 400;
        message.message = 'not supported geolocation, navigator';
        callback(message);
      }
    },
    [messageSeq],
  );

  return (
    <WebContext.Provider
      value={{
        handleGetLocation,
      }}
    >
      {children}
    </WebContext.Provider>
  );
}

export default WebProvider;

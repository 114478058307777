export const GNB_TYPE_VALUE = {
  HOME: 'HOME',
  INTERIOR: 'INTERIOR',
  MALL: 'MALL',
  SEARCH: 'SEARCH',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  JOIN: 'JOIN',
  SHOP_SEARCH: 'SHOP_SEARCH',
  CART: 'CART',
  MY: 'MY',
} as const;

import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import { BrazeCustomEventProperty, BrazePurchaseProperty } from '@/types/braze';

declare const window: Window & {
  braze?: any;
};

type BrazeToken =
  | {
      type: 'customEvent';
      eventName: string;
      eventProperties: BrazeCustomEventProperty | null;
    }
  | {
      type: 'purchase';
      productId: string;
      price: number;
      currencyCode: string;
      quantity: number;
      purchaseProperties: BrazePurchaseProperty | null;
    };

type LogCustomEvent = (args: {
  eventName: string;
  eventProperties?: BrazeCustomEventProperty;
  isWebOnly?: boolean;
  isAppOnly?: boolean;
}) => void;

type LogPurchase = (args: {
  productId?: string;
  price: number;
  currencyCode?: string;
  quantity?: number;
  purchaseProperties?: BrazePurchaseProperty;
  isWebOnly?: boolean;
  isAppOnly?: boolean;
}) => void;

const BrazeContext = createContext({
  logCustomEvent: () => {},
  logPurchase: () => {},
} as {
  logCustomEvent: LogCustomEvent;
  logPurchase: LogPurchase;
});

export const useBraze = () => {
  const context = useContext(BrazeContext);

  if (context === undefined) {
    throw new Error('useBraze must be used within a BrazeContext');
  }

  return context;
};

export interface BrazeProviderProps {
  children: ReactNode | ReactNode[];
}

const BrazeProvider = ({ children }: BrazeProviderProps) => {
  const [braze, setBraze] = useState<any>();
  const [brazeTokens, setBrazeTokens] = useState<BrazeToken[]>([]);

  // obtain the Braze instance.
  const waitUntilBrazeReady = useCallback(() => {
    if (!braze) {
      // Braze should initialize, so wait until it's ready.
      const intervalId = setInterval(() => {
        // Try to check whether Braze is ready.
        const brazeInstance = (window as any).braze;

        if (brazeInstance) {
          brazeInstance?.getUser()?.getUserId(() => {});
          setBraze(brazeInstance);
        }
        // Braze has been ready.
        clearInterval(intervalId);
      }, 1000);
    }
  }, [braze]);

  useEffect(() => {
    window.addEventListener('load', waitUntilBrazeReady);
    return () => {
      window.removeEventListener('load', waitUntilBrazeReady);
    };
  }, [waitUntilBrazeReady]);

  // proceed the stored tokens.
  useEffect(() => {
    if (braze && brazeTokens.length > 0) {
      for (let index = 0; index < brazeTokens.length; index++) {
        const token = brazeTokens[index];

        if (token.type === 'customEvent') {
          braze.logCustomEvent(token.eventName, token.eventProperties);
        }
        if (token.type === 'purchase') {
          braze.logPurchase(
            token.productId,
            token.price,
            token.currencyCode,
            token.quantity,
            token.purchaseProperties,
          );
        }
      }
      setBrazeTokens([]);
    }
  }, [braze, brazeTokens]);

  const addBrazeToken = useCallback((token: BrazeToken) => {
    setBrazeTokens((stack) => _.uniqWith([...stack, token], _.isEqual));
  }, []);

  const logCustomEvent = useCallback(
    ({
      eventName,
      eventProperties,
    }: {
      eventName: string;
      eventProperties?: BrazeCustomEventProperty;
    }) => {
      addBrazeToken({
        type: 'customEvent',
        eventName,
        eventProperties: eventProperties || null,
      });
    },
    [addBrazeToken],
  );

  const logPurchase = useCallback(
    ({
      productId = 'all',
      price,
      currencyCode = 'USD',
      quantity = 1,
      purchaseProperties,
    }: {
      productId?: string;
      price: number;
      currencyCode?: string;
      quantity?: number;
      purchaseProperties?: BrazePurchaseProperty;
    }) => {
      addBrazeToken({
        type: 'purchase',
        productId,
        price,
        currencyCode,
        quantity,
        purchaseProperties: purchaseProperties || null,
      });
    },
    [addBrazeToken],
  );

  return (
    <BrazeContext.Provider value={{ logCustomEvent, logPurchase }}>
      <BrazeContext.Consumer>{(_) => <>{children}</>}</BrazeContext.Consumer>
    </BrazeContext.Provider>
  );
};

export default BrazeProvider;

import {
  Box,
  Icongraphy,
  IcongraphyProps,
  VStack,
  colorChip,
} from '@design-system/pc';
/* eslint-disable no-unused-vars */
import React, {
  Children,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';

import { IconProps } from '@/ui/Icons/types/Icon.type';
import Image from '@/ui/Image';
import ImageWithBg from '@/feat-components/OnlineEstimate/ImageWithBg';
import { LoadImage } from '@display-component/pc';
import { MAX_Z_INDEX, theme } from '@/styles';
import { useRouter } from 'next/router';

interface ImageDetailModalContextType {
  isOpen: boolean;
  handleOpen: (images: string[], page: number) => void;
  handleClose: () => void;
}

const ImageDetailModalContext = createContext<ImageDetailModalContextType>({
  isOpen: false,
  handleOpen: () => {},
  handleClose: () => {},
});

export function useImageDetailModal() {
  const context = useContext(ImageDetailModalContext);
  if (context === undefined) {
    throw new Error(
      'useLoadingModal must be used within a LoadingModalContext',
    );
  }
  return context;
}

function ImageDetailModalProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [images, setImages] = useState<string[]>([]);
  const ref = useRef<HTMLDivElement>(null);

  const handleOpen = (images: string[], page: number) => {
    setImages(images);
    setPage(page);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handlePrevClick = () => {
    const newPage = page - 1 >= 0 ? page - 1 : images.length - 1;
    setPage(newPage);
  };
  const handleNextClick = () => {
    const newPage = page + 1 < images.length ? page + 1 : 0;
    setPage(newPage);
  };

  useEffect(() => {
    ref.current?.children[page]?.scrollIntoView({ behavior: 'smooth' });
  }, [isOpen, page]);

  return (
    <ImageDetailModalContext.Provider
      value={{
        isOpen,
        handleOpen,
        handleClose,
      }}
    >
      {children}
      <ImageDetailModalContext.Consumer>
        {() => (
          <DimmedBox isOpen={isOpen}>
            <VStack alignment="end" width="870px">
              <ButtonBox>
                <Icongraphy
                  variant="ic_close"
                  resize
                  size={36}
                  fillColor={colorChip.white}
                  onClick={handleClose}
                />
              </ButtonBox>
              <Box alignSelf="center" height="528px" borderRadius={2} mb="32px">
                <LoadImage src={images[page]} />
              </Box>
              <Box position="relative">
                <ListCont ref={ref}>
                  {Children.toArray(
                    images.map((image, i) => (
                      <ButtonBox onClick={() => setPage(i)}>
                        <ImageWithBg
                          src={image}
                          width="72px"
                          minWidth="72px"
                          height="72px"
                          minHeight="72px"
                          backgroundColor="white"
                          borderRadius={2}
                          border={
                            i === page ? `2px solid ${colorChip.blue[500]}` : ''
                          }
                        />
                      </ButtonBox>
                    )),
                  )}
                </ListCont>
                <MovePagination
                  variant="ic_arrow_left"
                  onClick={handlePrevClick}
                  location="LEFT"
                  isVisible={images.length >= 11 && page !== 0}
                />
                <MovePagination
                  variant="ic_arrow_right"
                  onClick={handleNextClick}
                  location="RIGHT"
                  isVisible={images.length >= 11 && page !== images.length - 1}
                />
              </Box>
            </VStack>
          </DimmedBox>
        )}
      </ImageDetailModalContext.Consumer>
    </ImageDetailModalContext.Provider>
  );
}

export default ImageDetailModalProvider;

const MovePagination = ({
  variant,
  onClick,
  location,
  isVisible,
}: {
  variant: IcongraphyProps['variant'];
  onClick: () => void;
  location: 'LEFT' | 'RIGHT';
  isVisible: boolean;
}) => {
  return (
    <MovePaginationCont
      backgroundColor={'var(--dimmed-dimmed-2, rgba(0, 0, 0, 0.50))'}
      onClick={onClick}
      location={location}
      isVisible={isVisible}
    >
      <Icongraphy variant={variant} size={20} fillColor={colorChip.white} />
    </MovePaginationCont>
  );
};

const MovePaginationCont = styled(Box)<{
  location: 'LEFT' | 'RIGHT';
  isVisible: boolean;
}>`
  position: absolute;
  ${(props) =>
    props.location === 'LEFT'
      ? css`
          left: 0;
        `
      : css`
          right: 0;
        `};
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  top: 14px;
  padding: 10px;
  border-radius: 2px;
  &:hover {
    cursor: pointer;
  }
`;

const ListCont = styled(Box)`
  width: 870px;
  display: flex;
  overflow: scroll;
  gap: 8px;
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const DimmedBox = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  position: fixed;
  z-index: ${MAX_Z_INDEX};
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.accent_colors.dimmed[1]};
`;

const ButtonBox = styled(Box)`
  margin-bottom: 20px;
  &:hover {
    cursor: pointer;
  }
`;

import { NameValueType, ResizeImageType } from '@/types';
import { format, isValid, parse } from 'date-fns';
import _ from 'lodash';
import { FieldErrors, UseFormReturn } from 'react-hook-form';
import ko from 'date-fns/locale/ko';
import getRawBody from 'raw-body';
import querystring from 'query-string';
import { IncomingMessage } from 'http';

/**
 * 리사이징 이미지 url 생성
 * @param param0
 * @returns
 */
export const getResizeImage = ({
  url,
  w = 0,
  h = 0,
  q = 95,
}: ResizeImageType) => {
  if (
    _.isEmpty(url) ||
    w === undefined ||
    h === undefined ||
    w === 0 ||
    h === 0
  ) {
    return url;
  }

  return `${url}?w=${w}&h=${h}&q=${q}`;
};

const getObjectErrorByFieldName = (errors: FieldErrors, fieldName: string) => {
  if (fieldName.indexOf('.') >= 0) {
    let result: { [x: string]: any } = errors;
    const arrFieldName = fieldName.split('.');
    arrFieldName.forEach((name) => (result = result?.[name]));
    return result;
  } else {
    return errors?.[fieldName];
  }
};

export const getFieldErrorMessage = (
  errors: FieldErrors,
  fieldName: string,
) => {
  return getObjectErrorByFieldName(errors, fieldName)?.message || '';
};

export const getFieldErrorMessages = (
  errors: FieldErrors,
  fieldNames: string[],
) => {
  for (const fieldName of fieldNames) {
    if (hasFieldError(errors, fieldName))
      return getFieldErrorMessage(errors, fieldName);
  }
};

export const hasFieldError = (errors: FieldErrors, fieldName: string) =>
  getObjectErrorByFieldName(errors, fieldName) !== undefined;

export const hasFieldErrors = (errors: FieldErrors, fieldNames: string[]) => {
  return (
    fieldNames.findIndex((fieldName) => hasFieldError(errors, fieldName)) >= 0
  );
};

/**
 * number type 인경우 px 스트링으로 변환해서 리턴해준다.
 * @param size
 */
export const convertNumberPx = (size: number | string): string => {
  return typeof size === 'number' ? `${size}px` : size;
};

export const getAsset = (path: string) =>
  (process.env.NEXT_PUBLIC_WEB_ASSET_HOST || '') + path;

/**
 * 전화번호 스트링을 구분자로 분리해서 반환한다.
 * ex) toPhoneFormatString('01011111111', '-');
 *   -> 010-1111-1111
 * @param value
 * @param separator
 * @returns
 */
export const toPhoneSeparatorString = (
  value: string,
  separator: string = '-',
) => {
  if (!value) return '';
  value = value.replace(/[^0-9]/g, '');
  return value.replace(
    /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
    `$1${separator}$2${separator}$3`,
  );
};

export const getNameByValue = <T extends {}>(
  nameValues: NameValueType<T>[],
  value?: T,
) => {
  return nameValues?.find((item) => item?.value === value)?.name || '';
};

/**
 * 데이트 형태의 스트링을 원하는 포맷으로 돌려준다.
 * @param param0
 * @returns
 */
export const toDateStringFormat = ({
  dateString,
  orgFormat = 'yyyy.MM.dd',
  returnFormat = 'yyyy-MM-dd',
}: {
  dateString: string;
  orgFormat?: string;
  returnFormat?: string;
}): string => {
  const parsedDate = parse(dateString, orgFormat, new Date());
  return isValid(parsedDate)
    ? format(parsedDate, returnFormat, {
        locale: ko,
      })
    : '';
};

/**
 * 객체 내부에 빈값(null, undefined, '', [], {}) 등이 담긴경우 제거 해준다.
 * @param originalObj
 * @returns
 */
export const getReplaceEmptyObject = <T>(
  originalObj: T,
  options?: {
    /**
     * 해당 키 제거
     */
    removeKeys?: string[];
    /**
     * prefix 로 시작하는 키 삭제
     */
    removeStartPrefix?: {
      keys?: string[];
      whitelist?: string[];
    };
  },
) => {
  const stack: { obj: any; key: string }[] = [];
  const replaceNull = (obj: any, depth: number = 0) => {
    for (const [key, value] of Object.entries(obj)) {
      const hasRemovePrefixKeys = options?.removeStartPrefix?.keys?.some(
        (prefix) => {
          return (
            !options?.removeStartPrefix?.whitelist?.includes(key) &&
            key.indexOf(prefix) === 0
          );
        },
      );
      /** 빈 값인경우 삭제 */
      if (
        hasRemovePrefixKeys ||
        options?.removeKeys?.includes(key) ||
        value == null ||
        (_.isString(value) && _.isEmpty(value)) ||
        (_.isArray(value) && _.isEmpty(value)) ||
        (_.isObject(value) && _.isEmpty(value))
      ) {
        delete obj[key];
      } else {
        // object형이면서 빈객체가 아닌경우 해당 객체와 키를 stack에 남고 재귀
        if (!_.isEmpty(obj[key]) && _.isObject(obj[key])) {
          stack.push({
            obj,
            key,
          });
        }
      }
    }
    // stack 에 담겨 있는 요소 들을 처리한다.
    let stackItem = stack.pop();
    while (!_.isEmpty(stackItem)) {
      const value = stackItem.obj[stackItem.key];
      replaceNull(value, stack.length);
      if (_.isObject(value) && _.isEmpty(value)) {
        delete stackItem.obj[stackItem.key];
      }
      stackItem = stack.pop();
    }

    return obj;
  };
  return () => {
    if (originalObj == null) return null;

    const obj = _.cloneDeep(originalObj);
    replaceNull(obj, 0);

    return obj;
  };
};

export const toSquareMeter = (pyeong: number): number =>
  Math.ceil(Math.round(pyeong * 3.305785 * 100) / 100);

export const toPyeong = (squareMeter: number): number =>
  Math.ceil(Math.round((squareMeter / 3.305785) * 10) / 10);

/**
 * http body를 구해준다.
 * @param req : 서버 req 객체
 * @param options
 * @returns
 */
export const getServerBody = async (
  req: IncomingMessage,
  options?: getRawBody.Options,
) => {
  if (req.method !== 'POST') return null;
  const body = await getRawBody(req, options);
  const contentType = req.headers['content-type'];
  if (contentType === 'application/json') {
    return JSON.parse(body.toString());
  }

  if (contentType?.indexOf('application/x-www-form-urlencoded') !== -1)
    return querystring.parse(body.toString());

  throw new Error(`Not supported content type : ${contentType}`);
};

/**
 * hook form 객체 데이터를 초기화한다
 * @param param0
 */
export const resetFields = ({
  objKeyPath,
  formUtils,
}: {
  objKeyPath: string;
  formUtils: UseFormReturn<any>;
}) => {
  _.keys(formUtils.getValues(objKeyPath)).forEach((key) => {
    formUtils.resetField(`${objKeyPath}.${key}`);
  });
  formUtils.setValue(objKeyPath, null);
};

export const comma = (value?: number) => {
  return value ? Number(value).toLocaleString('ko-KR') : value;
};

import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import useClipboard from '@/hooks/useClipboard';
import {
  BaseButton,
  HStack,
  Icongraphy,
  LinePopup,
  VStack,
  Text,
  useToast,
} from '@design-system/pc';
import OutsideClickHandler from 'react-outside-click-handler';
import TrackingService from '@/services/tracking';
import {
  GNB_HEIGHT,
  HEADER_HEIGHT,
  POPUP_Z_INDEX,
  TOAST_Z_INDEX,
} from '@/styles';

type ShareData = { url: string };
type HandleOpen = ({ url }: ShareData) => void;

declare const window: any;

const ShareContext = createContext({
  handleOpen: (_) => {},
} as {
  handleOpen: HandleOpen;
});

export const useShare = () => {
  const context = useContext(ShareContext);

  if (context === undefined) {
    throw new Error('useShare must be used within a ShareContext');
  }

  return context;
};

export interface ShareProviderProps {
  children: ReactNode | ReactNode[];
}

const ShareProvider = ({ children }: ShareProviderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<ShareData>();
  const { toast } = useToast();

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOpen = useCallback<HandleOpen>((data) => {
    setData(data);
    setIsOpen(true);
  }, []);

  const { handleCopy: copyClipboard } = useClipboard();

  const handleShareFacebook = useCallback(() => {
    window.open(`http://www.facebook.com/sharer/sharer.php?u=${data!.url}`);
  }, [data]);

  const handleShareUrl = useCallback(() => {
    copyClipboard(data!.url);
    toast.success('주소가 복사되었습니다.', {
      alignment: 'center',
      placement: 'top',
      width: '680px',
      variant: 'basic',
      offset: HEADER_HEIGHT + GNB_HEIGHT + 32,
      zIndex: TOAST_Z_INDEX,
    });
  }, [copyClipboard, data, toast]);

  return (
    <ShareContext.Provider value={{ handleOpen }}>
      {children}
      <LinePopup
        isOpen={isOpen}
        handleClose={handleClose}
        isNoDisplayDimmed={true}
        portalId="DefaultInfoContainer"
        position="absolute"
        left="auto"
        top={20}
        right={-50}
        zIndex={POPUP_Z_INDEX}
      >
        <OutsideClickHandler
          onOutsideClick={(e) => {
            const target = e.target as HTMLElement;
            // modal 밖에서 click 할 경우에만 닫힘
            if (!target.classList.contains('BaseModal')) {
              handleClose();
            }
          }}
        >
          <HStack
            alignment="center"
            width="100%"
            className="ShareProviderPopUp"
          >
            <BaseButton
              p={0}
              onClick={handleShareFacebook}
              {...TrackingService.tagGTM('share_button', {
                'menu-value': 'facebook',
              })}
            >
              <VStack space={6} width={80}>
                <Icongraphy
                  variant="ic_share_facebook_custom_logo"
                  size={40}
                  resize
                />
                <HStack height={16}>
                  <Text variant="label_sm_m" color="color_chip.gray.600">
                    페이스북
                  </Text>
                </HStack>
              </VStack>
            </BaseButton>
            <BaseButton
              p={0}
              onClick={handleShareUrl}
              {...TrackingService.tagGTM('share_button', {
                'menu-value': 'url',
              })}
            >
              <VStack space={6} width={80}>
                <VStack
                  width={40}
                  height={40}
                  borderRadius="50%"
                  backgroundColor="color_chip.gray.100"
                  alignment="center"
                  space="center"
                >
                  <Icongraphy variant="ic_link" size={20} resize />
                </VStack>
                <HStack height={16}>
                  <Text variant="label_sm_m" color="color_chip.gray.600">
                    URL
                  </Text>
                </HStack>
              </VStack>
            </BaseButton>
          </HStack>
        </OutsideClickHandler>
      </LinePopup>
    </ShareContext.Provider>
  );
};

export default ShareProvider;

import Modal, { ModalProps } from '@/ui/Modal/Modal';
import { useRouter } from 'next/router';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

interface ModalOptions {
  modalProps?: Omit<ModalProps, 'isOpen' | 'onClose'> | undefined;
}
interface ModalContextType {
  isOpen: boolean;
  handleOpen: (options: ModalOptions) => void;
  handleClose: () => void;
}

const ModalContext = createContext<ModalContextType>({
  isOpen: false,
  handleOpen: () => {},
  handleClose: () => {},
});

export function useModal() {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a modalContext');
  }
  return context;
}

function ModalProvider({ children }: { children?: React.ReactNode }) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const router = useRouter();
  const [modalOption, setModalOption] = useState<Partial<ModalProps> | null>();

  const handleOpen = useCallback((modalOptions: ModalOptions) => {
    setModalOption({ ...modalOptions.modalProps });
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setModalOption(null);
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (!isOpen) return;
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  useEffect(() => {
    if (
      router.asPath !== history.state?.path &&
      router.asPath !== history.state?.as
    ) {
      setIsOpen(false);
    }
  }, [router]);

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        handleOpen,
        handleClose,
      }}
    >
      {children}
      <ModalContext.Consumer>
        {(value) => (
          <Modal isOpen={isOpen} {...modalOption} onClose={handleClose} />
        )}
      </ModalContext.Consumer>
    </ModalContext.Provider>
  );
}

export default ModalProvider;

/* eslint-disable no-unused-vars */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import Image from '@/ui/Image';
import styled from 'styled-components';
import { MAX_Z_INDEX, theme } from '@/styles';
import { useRouter } from 'next/router';

interface LoadingModalContextType {
  isOpen: boolean;
  handleOpen: (id: number) => void;
  handleClose: (id: number) => void;
}

export enum LOADING_MODAL_ID {
  STORAGE_SIMULATOR_REFRESH_PLAN,
  STORAGE_SIMULATOR_CHANGE_TYPE,
  STORAGE_SIMULATOR_LOAD_TYPE,
  STORAGE_SIMULATOR_LOAD_ALLGDS,
  STORAGE_SIMULATOR_SUBMIT,
  MY_CONSULT_STORAGE_LOAD,
}

const LoadingModalContext = createContext<LoadingModalContextType>({
  isOpen: false,
  handleOpen: () => {},
  handleClose: () => {},
});

export function useLoadingModal() {
  const context = useContext(LoadingModalContext);
  if (context === undefined) {
    throw new Error(
      'useLoadingModal must be used within a LoadingModalContext',
    );
  }
  return context;
}

function LoadingModalProvider({ children }: { children?: React.ReactNode }) {
  const [stack, setStack] = useState<number[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const router = useRouter();

  const handleOpen = useCallback((id: number) => {
    setStack((prev) => prev.concat(id));
  }, []);

  const handleClose = useCallback((id: number) => {
    setStack((prev) => prev.filter((each) => each !== id));
  }, []);

  useEffect(() => {
    setIsOpen(!!stack.length);
  }, [stack]);

  useEffect(() => {
    if (!isOpen) return;
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  useEffect(() => {
    if (
      router.asPath !== history.state?.path &&
      router.asPath !== history.state?.as
    ) {
      setIsOpen(false);
    }
  }, [router]);

  return (
    <LoadingModalContext.Provider
      value={{
        isOpen,
        handleOpen,
        handleClose,
      }}
    >
      {children}
      <LoadingModalContext.Consumer>
        {() => (
          <DimmedBox isOpen={isOpen}>
            <Image
              src="https://res.remodeling.hanssem.com/web/pc/assets/icons/loading-obj-new.gif"
              alt="로딩이미지"
              width="64px"
              height="64px"
            />
          </DimmedBox>
        )}
      </LoadingModalContext.Consumer>
    </LoadingModalContext.Provider>
  );
}

export default LoadingModalProvider;

const DimmedBox = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  position: fixed;
  z-index: ${MAX_Z_INDEX};
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.accent_colors.dimmed[1]};
`;

const typo = (
  fontSize: string,
  fontWeight: number,
  lineHeight: number,
  letterSpacing: string,
) => {
  return {
    fontSize,
    fontWeight,
    lineHeight,
    letterSpacing,
  };
};

export const typography = {
  // headline
  headline1: typo('32px', 700, 1.2, '0em'),
  headline2: typo('28px', 700, 1.4, '0em'),
  headline3: typo('24px', 700, 1.4, '0em'),
  headline4: typo('20px', 700, 1.4, '0em'),
  headline5: typo('18px', 600, 1.4, '0em'),
  headline6: typo('16px', 600, 1.4, '0em'),
  headline7: typo('14px', 600, 1.4, '0em'),
  // subtitle
  subtitle1: typo('32px', 500, 1.4, '0em'),
  subtitle2: typo('28px', 500, 1.4, '0em'),
  subtitle3: typo('24px', 500, 1.4, '0em'),
  subtitle4: typo('20px', 500, 1.4, '0em'),
  subtitle5: typo('18px', 500, 1.4, '0em'),
  subtitle6: typo('16px', 500, 1.4, '0em'),
  subtitle7: typo('14px', 500, 1.4, '0em'),
  // body
  body1: typo('16px', 400, 1.4, '0em'),
  body2: typo('14px', 400, 1.4, '0em'),
  // caption
  caption1: typo('12px', 400, 1.4, '0em'),
  caption2: typo('10px', 400, 1.4, '0em'),
  // button
  button: {
    xxlg_sb: typo('20px', 500, 1, '0em'),
    xlg_sb: typo('18px', 600, 1, '0em'),
    xlg_m: typo('18px', 500, 1, '0em'),
    xlg_r: typo('18px', 400, 1, '0em'),
    lg_b: typo('16px', 700, 1, '0em'),
    lg_m: typo('16px', 500, 1, '0em'),
    lg_r: typo('16px', 400, 1, '0em'),
    md_b: typo('14px', 700, 1, '0em'),
    md_m: typo('14px', 500, 1, '0em'),
    md_r: typo('14px', 400, 1, '0em'),
    sm_b: typo('12px', 700, 1, '0em'),
    sm_m: typo('12px', 500, 1, '0em'),
    sm_r: typo('12px', 400, 1, '0em'),
  },
  label: {
    lg_r: typo('16px', 400, 1, '0em'),
    md_r: typo('14px', 400, 1, '0em'),
    sm_r: typo('12px', 400, 1, '0em'),
  },
  // etc
  etc1: typo('40px', 700, 1.4, '0em'),
};

export type TypographyTypes = typeof typography;

export const colors = {
  // neutral_colors
  neutral_colors: {
    primary: '#121212',
    gray: {
      700: '#666666',
      600: '#999999',
      500: '#B3B3B3',
      200: '#E2E2E2',
      100: '#EFEFEF',
      50: '#f7f7f7',
    },
    white: '#ffffff',
  },
  // accent_colors
  accent_colors: {
    primary: {
      default: '#121212',
      light: '#F9F9F9',
    },
    red: {
      default: '#FF3C3C',
      light: '#FFEEE9',
    },
    blue: {
      default: '#0064FF',
      light: '#ECF4FF',
    },
    yellow: {
      default: '#FFB43C',
      light: '#FFF4CB',
    },
    // dimmed
    dimmed: {
      1: 'rgba(0, 0, 0, 0.5)', // BG
      2: 'rgba(0, 0, 0, 0.74)', // toast
      3: 'rgba(0, 0, 0, 0.20)', // img thumbnail
      4: 'rgba(0, 0, 0, 0.70)', // img preview
    },
    // error
    error: '#FF3C3C',
  },
  favorite_color_chip: {
    black: '#121212',
    white: '#FFFFFF',
    gray: '#D9D9D9',
    beige: '#FFE9D6',
    oak: '#C9AD97',
    walnut: '#8F5D50',
    red: '#FF3C3C',
    pink: '#F9C3C7',
    yellow: '#F7D25F',
    green: '#006E3B',
    mint: '#BCE4DD',
    blue: '#71B0F0',
    navy: '#000F74',
  },
};

export type ColorsTypes = typeof colors;

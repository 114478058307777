import {
  GoodsNoticeInfoTable,
  GoodsOptionNode,
  RecommendGoodsItemTagging,
} from '@/types';

export const GOODS_NO_OPTION: GoodsOptionNode = {
  titleSeq: 0,
  titleNm: '',
  artiSeq: 0,
  artiNm: '',
  gdsStatCd: 'N',
  isLast: true,
  unitNo: 0,
  price: {
    normalPrice: 0,
    dcPrice: 0,
    couponPrice: 0,
    unitPrice: 0,
    dcRate: 0,
  },
  notiOfStockYn: false,
  nextOptionList: [],
};

export const GOODS_ASK_INFO_TABLE: GoodsNoticeInfoTable = [
  {
    name: '업체명',
    key: 'saleCorpNm',
  },
  {
    name: '대표상담번호',
    key: 'custTelNo',
  },
];

export const GOODS_DETAIL_NOTICE_INFO_TABLE_MAP: {
  code: string;
  list: GoodsNoticeInfoTable;
}[] = [
  {
    // 침구류
    code: '5',
    list: [
      {
        name: '색상',
        key: 'gdsColor',
      },
      {
        name: '사이즈(W*D*H)',
        key: 'gdsSize',
      },
      {
        name: '구성품',
        key: 'component',
      },
      {
        name: '재질or주요소재',
        key: 'material',
      },
      {
        name: '충전제',
        key: 'filler',
      },
      {
        name: '수입여부',
        key: 'importYn',
      },
      {
        name: '출시년월',
        key: 'launchYymm',
      },
      {
        name: '제조국/원산지',
        key: 'origin',
      },
      {
        name: '제조사',
        key: 'manufacture',
      },
      {
        name: '배송/설치비용',
        key: 'deliveryFee',
      },
      {
        name: '품질보증기준',
        key: 'qualityAssurance',
      },
      {
        name: 'A/S책임자',
        key: 'as',
      },
      {
        name: '세탁방법',
        key: 'laundry',
      },
    ],
  },
  {
    // 가구(침대, 쇼파, 싱크대, DIY제품)
    code: '6',
    list: [
      {
        name: '색상',
        key: 'gdsColor',
      },
      {
        name: '사이즈(W*D*H)',
        key: 'gdsSize',
      },
      {
        name: '구성품',
        key: 'component',
      },
      {
        name: '재질or주요소재',
        key: 'material',
      },
      {
        name: '수입여부',
        key: 'importYn',
      },
      {
        name: '출시년월',
        key: 'launchYymm',
      },
      {
        name: '제조국/원산지',
        key: 'origin',
      },
      {
        name: '제조사',
        key: 'manufacture',
      },
      {
        name: '배송/설치비용',
        key: 'deliveryFee',
      },
      {
        name: '품질보증기준',
        key: 'qualityAssurance',
      },
      {
        name: 'A/S책임자',
        key: 'as',
      },
      {
        name: '전기용품 안전인증 필 유무',
        key: 'safeMarkYn',
      },
      {
        name: '정격전압/소비전력/에너지소비효율등급',
        key: 'energyRate',
      },
    ],
  },
  {
    // 주방용품
    code: '17',
    list: [
      {
        name: '색상',
        key: 'gdsColor',
      },
      {
        name: '사이즈(W*D*H)',
        key: 'gdsSize',
      },
      {
        name: '구성품',
        key: 'component',
      },
      {
        name: '재질or주요소재',
        key: 'material',
      },
      {
        name: '식품위생법 수입신고',
        key: 'importDeclaration',
      },
      {
        name: '수입여부',
        key: 'importYn',
      },
      {
        name: '출시년월',
        key: 'launchYymm',
      },
      {
        name: '제조국/원산지',
        key: 'origin',
      },
      {
        name: '제조사',
        key: 'manufacture',
      },
      {
        name: '배송/설치비용',
        key: 'deliveryFee',
      },
      {
        name: '품질보증기준',
        key: 'qualityAssurance',
      },
      {
        name: 'A/S책임자',
        key: 'as',
      },
      {
        name: '동일모델출시연월',
        key: 'modelYmdt',
      },
    ],
  },
  {
    // 영유아용품
    code: '23',
    list: [
      {
        name: '색상',
        key: 'gdsColor',
      },
      {
        name: '사이즈(W*D*H)',
        key: 'gdsSize',
      },
      {
        name: '구성품',
        key: 'component',
      },
      {
        name: '재질or주요소재',
        key: 'material',
      },
      {
        name: '사용연령',
        key: 'age',
      },
      {
        name: '수입여부',
        key: 'importYn',
      },
      {
        name: '출시년월',
        key: 'launchYymm',
      },
      {
        name: '제조국/원산지',
        key: 'origin',
      },
      {
        name: '제조사',
        key: 'manufacture',
      },
      {
        name: '배송/설치비용',
        key: 'deliveryFee',
      },
      {
        name: '품질보증기준',
        key: 'qualityAssurance',
      },
      {
        name: 'A/S책임자',
        key: 'as',
      },
      {
        name: '동일모델출시연월',
        key: 'modelYmdt',
      },
      {
        name: '취급방법 및 취급시주의사항',
        key: 'handling',
      },
    ],
  },
  {
    // 서적
    code: '26',
    list: [
      {
        name: '색상',
        key: 'gdsColor',
      },
      {
        name: '사이즈(W*D*H)',
        key: 'gdsSize',
      },
      {
        name: '구성품',
        key: 'component',
      },
      {
        name: '재질or주요소재',
        key: 'material',
      },
      {
        name: '수입여부',
        key: 'importYn',
      },
      {
        name: '출시년월',
        key: 'launchYymm',
      },
      {
        name: '제조국/원산지',
        key: 'origin',
      },
      {
        name: '제조사',
        key: 'manufacture',
      },
      {
        name: '배송/설치비용',
        key: 'deliveryFee',
      },
      {
        name: '품질보증기준',
        key: 'qualityAssurance',
      },
      {
        name: 'A/S책임자',
        key: 'as',
      },
    ],
  },
  {
    // 기타(조명,데코)
    code: '35',
    list: [
      {
        name: '색상',
        key: 'gdsColor',
      },
      {
        name: '사이즈(W*D*H)',
        key: 'gdsSize',
      },
      {
        name: '구성품',
        key: 'component',
      },
      {
        name: '재질or주요소재',
        key: 'material',
      },
      {
        name: '수입여부',
        key: 'importYn',
      },
      {
        name: '출시년월',
        key: 'launchYymm',
      },
      {
        name: '제조국/원산지',
        key: 'origin',
      },
      {
        name: '제조사',
        key: 'manufacture',
      },
      {
        name: '배송/설치비용',
        key: 'deliveryFee',
      },
      {
        name: '품질보증기준',
        key: 'qualityAssurance',
      },
      {
        name: 'A/S책임자',
        key: 'as',
      },
    ],
  },
  {
    // 가공식품
    code: '21',
    list: [
      {
        name: '식품위생법에 따른 표시사항',
        key: 'foodNotice',
      },
      {
        name: '식품유형',
        key: 'foodType',
      },
      {
        name: '소재지/수입자',
        key: 'foodLocation',
      },
      {
        name: '출시년월',
        key: 'launchYymm',
      },
      {
        name: '제조연월일/유통기한 품질유지기한',
        key: 'foodYmdt',
      },
      {
        name: '포장단위별 용량(중량)/수량',
        key: 'amount',
      },
      {
        name: '원재료명 및 함량',
        key: 'material',
      },
      {
        name: '영양성분',
        key: 'nutritionContents',
      },
      {
        name: '유전자재조합식품여부',
        key: 'gmoYn',
      },
      {
        name: '영유아식/체중조절식품여부',
        key: 'weightControlYn',
      },
      {
        name: '식품위생법 수입신고',
        key: 'importDeclaration',
      },
      {
        name: '소비자상담 전화번호',
        key: 'custCounselTel',
      },
    ],
  },
  {
    // 가정용 전기제품(냉장고/세탁기/식기세척기/전자레인지)
    code: '8',
    list: [
      {
        name: '모델명',
        key: 'modelNm',
      },
      {
        name: '전기용품 안전인증 필 유무',
        key: 'safeMarkYn',
      },
      {
        name: '정격전압/소비전력/에너지소비효율등급',
        key: 'energyRate',
      },
      {
        name: '출시년월',
        key: 'launchYymm',
      },
      {
        name: '제조사',
        key: 'manufacture',
      },
      {
        name: '제조국',
        key: 'origin',
      },
      {
        name: '크기',
        key: 'gdsSize',
      },
      {
        name: '품질보증기준',
        key: 'qualityAssurance',
      },
      {
        name: 'A/S 책임자와 전화번호',
        key: 'as',
      },
    ],
  },
  {
    // 건강기능식품
    code: '41',
    list: [
      {
        name: '식품위생법에 따른 표시사항',
        key: 'foodNotice',
      },
      {
        name: '식품유형',
        key: 'foodType',
      },
      {
        name: '소재지/수입자',
        key: 'foodLocation',
      },
      {
        name: '출시년월',
        key: 'launchYymm',
      },
      {
        name: '제조연월일/유통기한 품질유지기한',
        key: 'foodYmdt',
      },
      {
        name: '포장단위별 용량(중량)/수량',
        key: 'amount',
      },
      {
        name: '원재료명 및 함량',
        key: 'material',
      },
      {
        name: '영양성분',
        key: 'nutritionContents',
      },
      {
        name: '유전자재조합식품여부',
        key: 'gmoYn',
      },
      {
        name: '소비자상담 전화번호',
        key: 'custCounselTel',
      },
      {
        name: '기능정보',
        key: 'foodFncInfo',
      },
      {
        name: '섭취량, 섭취방법 및 주의사항',
        key: 'intakeInfo',
      },
      {
        name: '부작용발생 가능성 여부',
        key: 'sideEffectChk',
      },
      {
        name: '질병 예방, 치료의약품 아님 명시',
        key: 'explicitNonMedicine',
      },
      {
        name: '표시광고 사전심의번호',
        key: 'preCensorshipNo',
      },
    ],
  },
  {
    // 자동차 요품(자동차 부품/기타 자동차 융품)
    code: '42',
    list: [
      {
        name: '모델명',
        key: 'modelNm',
      },
      {
        name: '출시년월',
        key: 'launchYymm',
      },
      {
        name: '제조국/원산지',
        key: 'origin',
      },
      {
        name: '제조사',
        key: 'manufacture',
      },
      {
        name: '수입여부',
        key: 'importYn',
      },
      {
        name: '크기',
        key: 'gdsSize',
      },
      {
        name: '적용차종',
        key: 'carType',
      },
      {
        name: '제품사용으로 인한 위험 및 유의사항(연료절감장치에 한함)',
        key: 'cautionInfo',
      },
      {
        name: '품질보증기준',
        key: 'qualityAssurance',
      },
      {
        name: 'A/S책임자와 전화번호',
        key: 'as',
      },
    ],
  },
];

export const GOODS_DETAIL_DEALER_INFO_TABLE: GoodsNoticeInfoTable = [
  {
    name: '상호',
    key: 'saleCorpNm',
  },
  {
    name: '대표자',
    key: 'ceoNm',
  },
  {
    name: '사업장소재지',
    key: 'newCorpZipNoAddr',
  },
  {
    name: '고객센터 전화번호',
    key: 'telNo',
  },
  {
    name: 'E-mail',
    key: 'email',
  },
  {
    name: '사업자 등록번호',
    key: 'bizNo',
  },
  {
    name: '통신판매업 신고번호',
    key: 'prmsnMgtNo',
  },
];

export const GOODS_DETAIL_DELIVERY_MANAGEMENT_SWR_KEY =
  'GoodsDetailDeliveryManagementSwrKey';

export const DRAWER_POPUP_DELIVERY_ADDRESS_MANAGEMENT =
  'DrawerPopupDeliveryAddressManagement';

export const RECOMMEND_GOODS_ITEM_TAG_NAME: Record<
  RecommendGoodsItemTagging,
  string
> = {
  view: 'recommend_view_goods_item',
  purchase: 'recommend_buy_goods_item',
  series: 'recommend_series_goods_item',
};

export const THUMBNAIL_AREA_SIZE = 500;
export const ZOOM_AREA_SIZE = 128;
export const ZOOM_DISPLAY_SIZE = 360;
export const ZOOM_RATIO = 2;

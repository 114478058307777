import {
  StorageGoods,
  GoodsType,
  StorageType,
  DoorType,
  DoorColor,
  InstallSpaceType,
  InstallShape,
  FurnitureColor,
} from '@store/remodeling-manager';

import { StepItem, NameValueType, ExtendedNameValueType } from '@/types';

export const DEFAULT_STEP_LIST: StepItem[] = [
  {
    seq: 1,
    title: '기본정보',
    type: 'consult',
    icon: 'ic_my_solid',
    gtm: {
      exitEvent: '',
    },
    subSteps: [],
  },
  {
    seq: 2,
    title: '상품정보',
    icon: 'ic_shopping_solid',
    type: 'goodsInfo',
    subSteps: [
      {
        seq: 1,
        title: '상품유형',
        type: 'goodsType',
        required: true,
        gtm: {
          exitEvent: '',
        },
      },
      {
        seq: 2,
        title: '수납방식',
        type: 'storageType',
        required: true,
        gtm: {
          exitEvent: '',
        },
      },
      {
        seq: 3,
        title: '수납물품',
        type: 'storageGoods',
        required: true,
        gtm: {
          exitEvent: '',
        },
      },
      {
        seq: 4,
        title: '스타일',
        type: 'storageGoodsStyle',
        required: true,
        gtm: {
          exitEvent: '',
        },
      },
    ],
  },
  {
    seq: 3,
    title: '공간정보',
    type: 'spaceInfo',
    icon: 'ic_ruler_solid',
    subSteps: [
      {
        seq: 1,
        title: '설치공간',
        type: 'installSpace',
        required: true,
        gtm: {
          exitEvent: '',
        },
      },
      {
        seq: 2,
        title: '설치모양',
        type: 'installShape',
        required: true,
        gtm: {
          exitEvent: '',
        },
      },
      {
        seq: 3,
        title: '공간사이즈',
        type: 'spaceSize',
        required: true,
        gtm: {
          exitEvent: '',
        },
      },
      {
        seq: 4,
        title: '남기는말',
        type: 'comment',
        required: true,
        gtm: {
          exitEvent: '',
        },
      },
    ],
  },
];

export const GOODS_TYPES: ExtendedNameValueType<GoodsType>[] = [
  {
    name: '옷장',
    subName: '이동이 간편한',
    value: {
      value: 'CLOSET',
      name: '옷장',
    },
    image: '/assets/image/consult-storage/goods_type_option_img1.png',
  },
  {
    name: '드레스룸',
    subName: '자유로운 모듈',
    value: {
      value: 'DRESS_ROOM',
      name: '드레스룸',
    },
    image: '/assets/image/consult-storage/goods_type_option_img2.png',
  },
  {
    name: '붙박이장',
    subName: '벽과 같은 일체형',
    value: {
      value: 'BUILTIN',
      name: '붙박이장',
    },
    image: '/assets/image/consult-storage/goods_type_option_img3.png',
  },
  {
    name: '혼합형',
    subName: '드레스룸+옷장',
    value: {
      value: 'MIX',
      name: '혼합형',
    },
    image: '/assets/image/consult-storage/goods_type_option_img4.png',
  },
];

export const STORAGE_TYPES: ExtendedNameValueType<StorageType>[] = [
  {
    name: '걸이집중형',
    value: {
      value: 'HANGER',
      name: '걸이집중형',
    },
    image: '/assets/image/consult-storage/storage_type_option_img1.png',
  },
  {
    name: '선반집중형',
    value: {
      value: 'SHELF',
      name: '선반집중형',
    },
    image: '/assets/image/consult-storage/storage_type_option_img2.png',
  },
  {
    name: '서랍집중형',
    value: {
      value: 'DRAWER',
      name: '서랍집중형',
    },
    image: '/assets/image/consult-storage/storage_type_option_img3.png',
  },
];

export const STORAGE_GOODS_ITEMS: NameValueType<StorageGoods>[] = [
  {
    name: '롱코트나 원피스가 많아요',
    value: {
      value: 'STORAGE_GOODS_01',
      name: '롱코트나 원피스가 많아요',
    },
  },
  {
    name: '짧은 자켓이나, 가디건이 많아요',
    value: {
      value: 'STORAGE_GOODS_02',
      name: '짧은 자켓이나, 가디건이 많아요',
    },
  },
  {
    name: '가방이나 모자가 많아요',
    value: {
      value: 'STORAGE_GOODS_03',
      name: '가방이나 모자가 많아요',
    },
  },
  {
    name: '악세사리가 많아요',
    value: {
      value: 'STORAGE_GOODS_04',
      name: '악세사리가 많아요',
    },
  },
  {
    name: '큰 이불이 있어요',
    value: {
      value: 'STORAGE_GOODS_05',
      name: '큰 이불이 있어요',
    },
  },
];

export const STORAGE_GOODS_STYLE_DOOR_TYPES: NameValueType<DoorType>[] = [
  {
    name: '슬라이딩',
    value: {
      value: 'SLIDING',
      name: '슬라이딩',
    },
  },
  {
    name: '여닫이',
    value: {
      value: 'SWING',
      name: '여닫이',
    },
  },
  {
    name: '상관없어요',
    value: {
      value: 'DONT_CARE',
      name: '상관없어요',
    },
  },
];

export const STORAGE_GOODS_STYLE_DOOR_COLORS: ExtendedNameValueType<DoorColor>[] =
  [
    {
      name: '화이트',
      value: {
        value: 'WHITE',
        name: '화이트',
      },
      color: '#fff',
    },
    {
      name: '베이지',
      value: {
        value: 'BEIGE',
        name: '베이지',
      },
      color: '#FFE9D6',
    },
    {
      name: '그레이',
      value: {
        value: 'GRAY',
        name: '그레이',
      },
      color: '#D9D9D9',
    },
    {
      name: '상관없어요',
      value: {
        value: 'DONT_CARE',
        name: '상관없어요',
      },
    },
  ];

export const STORAGE_GOODS_STYLE_FURNITURE_COLORS: ExtendedNameValueType<FurnitureColor>[] =
  [
    {
      name: '화이트',
      value: {
        value: 'WHITE',
        name: '화이트',
      },
      color: '#fff',
    },
    {
      name: '베이지',
      value: {
        value: 'BEIGE',
        name: '베이지',
      },
      color: '#FFE9D6',
    },
    {
      name: '그레이',
      value: {
        value: 'GRAY',
        name: '그레이',
      },
      color: '#D9D9D9',
    },
    {
      name: '상관없어요',
      value: {
        value: 'DONT_CARE',
        name: '상관없어요',
      },
    },
  ];

export const INSTALL_SPACES: ExtendedNameValueType<InstallSpaceType>[] = [
  {
    name: '안방',
    value: {
      value: 'INNER_ROOM',
      name: '안방',
    },
  },
  {
    name: '옷방',
    value: {
      value: 'DRESS_ROOM',
      name: '옷방',
    },
  },
  {
    name: '자녀방',
    value: {
      value: 'CHILDREN_ROOM',
      name: '자녀방',
    },
  },
  {
    name: '다목적공간',
    value: {
      value: 'MULTI_ROOM',
      name: '다목적공간',
    },
  },
];

export const INSTALL_SHAPES: ExtendedNameValueType<InstallShape>[] = [
  {
    name: '-자',
    value: { value: 'INSTALL_SHAPE_01', name: '-자' },
    image: '/assets/image/consult-storage/install_shape_option_img1.png',
  },
  {
    name: '11자',
    value: { value: 'INSTALL_SHAPE_02', name: '11자' },
    image: '/assets/image/consult-storage/install_shape_option_img2.png',
  },
  {
    name: 'ㄱ자',
    value: { value: 'INSTALL_SHAPE_03', name: 'ㄱ자' },
    image: '/assets/image/consult-storage/install_shape_option_img3.png',
  },
  {
    name: 'ㄷ자',
    value: { value: 'INSTALL_SHAPE_04', name: 'ㄷ자' },
    image: '/assets/image/consult-storage/install_shape_option_img4.png',
  },
];

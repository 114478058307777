import React, { CSSProperties, ReactNode } from 'react';
import { theme } from '@/styles';
import styled, { css } from 'styled-components';

type SizeType = 'regular' | 'bold';
type EmphasisType = 'low' | 'medium' | 'high';
type ContentsButtonProps = {
  size: SizeType;
  emphasis: EmphasisType;
  children: ReactNode;
  width?: string;
  disabled?: boolean;
  onClick?: (params?: any) => void;
  style?: CSSProperties;
  type?: 'button' | 'submit' | 'reset' | undefined;
};

function ContentsButton({
  size = 'regular',
  emphasis = 'low',
  disabled = false,
  width = 'fit-content',
  children,
  onClick,
  style,
  type,
  ...props
}: ContentsButtonProps) {
  return (
    <ContentsButtonWrap
      size={size}
      emphasis={emphasis}
      disabled={disabled}
      width={width}
      onClick={onClick}
      style={style}
      type={type}
      {...props}
    >
      {children}
    </ContentsButtonWrap>
  );
}

const emphases = {
  low: {
    enabled: {
      bgColor: theme.colors.neutral_colors.white,
      borderColor: theme.colors.neutral_colors.gray[200],
      color: theme.colors.neutral_colors.primary,
    },
    disabled: {
      bgColor: theme.colors.neutral_colors.white,
      borderColor: theme.colors.neutral_colors.gray[200],
      color: theme.colors.neutral_colors.gray[600],
    },
    pressed: {
      bgColor: theme.colors.neutral_colors.gray[50],
      borderColor: theme.colors.neutral_colors.gray[200],
      color: theme.colors.neutral_colors.primary,
    },
  },
  medium: {
    enabled: {
      bgColor: theme.colors.neutral_colors.white,
      borderColor: theme.colors.neutral_colors.primary,
      color: theme.colors.neutral_colors.primary,
    },
    disabled: {
      bgColor: theme.colors.neutral_colors.white,
      borderColor: theme.colors.neutral_colors.gray[200],
      color: theme.colors.neutral_colors.gray[600],
    },
    pressed: {
      bgColor: theme.colors.neutral_colors.gray[50],
      borderColor: theme.colors.neutral_colors.primary,
      color: theme.colors.neutral_colors.primary,
    },
  },
  high: {
    enabled: {
      bgColor: theme.colors.neutral_colors.primary,
      borderColor: theme.colors.neutral_colors.primary,
      color: theme.colors.neutral_colors.white,
    },
    disabled: {
      bgColor: theme.colors.neutral_colors.gray[50],
      borderColor: 'transparent',
      color: theme.colors.neutral_colors.gray[600],
    },
    pressed: {
      bgColor: '#414141', // high Emphasis > pressed 임시 컬러
      borderColor: '#414141',
      color: theme.colors.neutral_colors.white,
    },
  },
};

const sizes = {
  regular: {
    textStyle: theme.typography.button.lg_r,
  },
  bold: {
    textStyle: theme.typography.button.lg_b,
  },
};

const btnSize = css`
  ${({ size }: { size: SizeType }) => css`
    ${sizes[size].textStyle}
  `}
`;

const btnEmphasis = css`
  ${({
    emphasis,
    disabled,
  }: {
    emphasis: EmphasisType;
    disabled: boolean;
  }) => css`
    background-color: ${!disabled
      ? emphases[emphasis]?.enabled.bgColor
      : emphases[emphasis]?.disabled.bgColor};
    border-color: ${!disabled
      ? emphases[emphasis]?.enabled.borderColor
      : emphases[emphasis]?.disabled.borderColor};
    color: ${!disabled
      ? emphases[emphasis]?.enabled.color
      : emphases[emphasis]?.disabled.color};
  `}
`;

const btnPressed = css`
  ${({
    emphasis,
    disabled,
  }: {
    emphasis: EmphasisType;
    disabled: boolean;
  }) => css`
    background-color: ${!disabled
      ? emphases[emphasis]?.pressed.bgColor
      : emphases[emphasis]?.disabled.bgColor};
    border-color: ${!disabled
      ? emphases[emphasis]?.pressed.borderColor
      : emphases[emphasis]?.disabled.borderColor};
    color: ${!disabled
      ? emphases[emphasis]?.pressed.color
      : emphases[emphasis]?.disabled.color};
  `}
`;

const ContentsButtonWrap = styled.button<{
  size: SizeType;
  emphasis: EmphasisType;
  disabled: boolean;
  width: string;
  type?: string;
}>`
  /* common */
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  height: 48px;
  border-radius: 30px;
  background-color: transparent;
  padding: 0px;

  /* button width */
  width: ${({ width }) => width};

  /* size */
  ${btnSize}

  /* emphasis */
  ${btnEmphasis}

  /* hover/pressed */
  &:hover,
  &:active {
    ${btnPressed}
  }
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

export default ContentsButton;

import { Box, BoxProps, colorChip } from '@design-system/pc';

import { LoadImage } from '@display-component/pc';
import React from 'react';

const ImageWithBg = ({
  src,
  width,
  height,
  ...props
}: BoxProps & {
  src: string;
  width: string;
  height: string;
}) => {
  return (
    <Box
      width={width}
      height={height}
      backgroundColor={colorChip.gray[400]}
      borderRadius="2px"
      {...props}
    >
      <LoadImage width={width} height={height} src={src} />
    </Box>
  );
};

export default ImageWithBg;

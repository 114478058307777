import { SendToMeAgreement } from '@/types';

/**
 * 기본 플랜 사이즈
 */
export const DEFAULT_PLAN_LENG = 3100;

export const SEND_TO_ME_AGREE_PRIVACY: SendToMeAgreement = {
  title: '개인정보 수집 및 이용동의',
  text: `붙박이장 셀프플래너 나에게 보내기 서비스 제공을 위해 아래와 같이 개인정보를 수집·이용하고자 합니다. 내용을 자세히 읽으신 후 동의해 주시기 바랍니다. 
  이용자가 동의한 내용 외의 다른 목적으로 사용하지 않으며, 이용자는 동의를 거부할 권리가 있습니다. 단, 동의 거부 시 나에게 보내기 서비스를 받으실 수 없습니다. 그 밖의 사항은 (주)한샘 개인정보처리 방침에 따릅니다.
  
  1. 개인정보 수집 및 이용 목적
  - 알림톡(카카오톡/SMS) 메시지 보내기
  - 제품 내역 및 견적 제공
  - 제품 구매 링크 제공
  
  2. 수집 항목
  휴대폰 번호
  
  3. 보유 및 이용 기간
  전송 후 180일까지`,
};

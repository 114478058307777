import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../store';
import { HYDRATE } from 'next-redux-wrapper';

import _ from 'lodash';
import { BrazeCustomEventProperty, BrazePurchaseProperty } from '@/types';

// Define a type for the slice state

export type BrazeToken =
  | {
      type: 'customEvent';
      eventName: string;
      eventProperties: BrazeCustomEventProperty | null;
    }
  | {
      type: 'purchase';
      productId: string;
      price: number;
      currencyCode: string;
      quantity: number;
      purchaseProperties: BrazePurchaseProperty | null;
    };

export interface TrackingState {
  pcid: string | null;
  pcid2: string | null;
  brazeTokens: BrazeToken[];
}

// Define the initial state using that type
const initialState: TrackingState = {
  pcid: null,
  pcid2: null,
  brazeTokens: [],
};

export const trackingSlice = createSlice({
  name: 'tracking',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    storePcid: (
      state,
      action: PayloadAction<{ pcid: string | null; pcid2: string | null }>,
    ) => {
      state.pcid = action.payload.pcid;
      state.pcid2 = action.payload.pcid2;
    },
    logBrazeToken: (state, action: PayloadAction<BrazeToken>) => {
      state.brazeTokens = _.uniqWith(
        [...state.brazeTokens, action.payload],
        _.isEqual,
      );
    },
    flushBrazeTokens: (state) => {
      state.brazeTokens = [];
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.tracking,
      };
    },
  },
});

export const { storePcid, logBrazeToken, flushBrazeTokens } =
  trackingSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectPcid = (state: RootState) => {
  const { pcid, pcid2 } = state.tracking;
  return { pcid, pcid2 };
};
export const selectBrazeTokens = (state: RootState) =>
  state.tracking.brazeTokens;

export default trackingSlice;

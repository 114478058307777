import Popup, { PopupProps } from '@/ui/Popup/Popup';
import { useRouter } from 'next/router';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

export interface popupOptions {
  popupProps: Omit<PopupProps, 'isOpen' | 'onClose'> | undefined;
}

interface PopupContextType {
  isOpen: boolean;
  handleOpen: (options: popupOptions) => void;
  handleClose: () => void;
}

const PopupContext = createContext<PopupContextType>({
  isOpen: false,
  handleOpen: () => {},
  handleClose: () => {},
});

export function usePopup() {
  const context = useContext(PopupContext);
  if (context === undefined) {
    throw new Error('usePopup must be used within a popupContext');
  }
  return context;
}

function PopupProvider({ children }: { children?: React.ReactNode }) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const router = useRouter();
  const [popupOption, setPopupOption] = useState<Partial<PopupProps> | null>();
  const handleOpen = useCallback((modalOptions: popupOptions) => {
    setPopupOption({ ...modalOptions.popupProps });
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setPopupOption(null);
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (!isOpen) return;
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(false);
  }, [router]);

  return (
    <PopupContext.Provider
      value={{
        isOpen,
        // key,
        handleOpen,
        handleClose,
      }}
    >
      {children}
      <PopupContext.Consumer>
        {(value) => (
          <Popup isOpen={isOpen} onClose={handleClose} {...popupOption} />
        )}
      </PopupContext.Consumer>
    </PopupContext.Provider>
  );
}

export default PopupProvider;

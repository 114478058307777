import { HttpClient } from '@store/base';
import { refreshAxios } from '@/di-container/axios';
import { LoginGateway, LogoutGateway, RefreshTokenResponse } from '@store/auth';
import { AnyAction, Dispatch } from 'redux';
import { failureLogin, successLogin } from '@/redux/reducers/auth';

declare const window: any;

export const authGateway = (reduxDispatcher?: Dispatch<AnyAction>) => {
  return {
    login: new ClientLoginGateway(
      new LoginGateway(
        `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/customer/mallLoginMain.do`,
      ),
    ),
    logout: new ClientLogoutGateway(new LogoutGateway()),
    refreshToken: new ClientRefreshTokenGateway(refreshAxios, reduxDispatcher),
  };
};

class ClientLoginGateway {
  constructor(readonly gateway: LoginGateway) {}

  async exec(returnPath?: string) {
    if (window) {
      const returnUrl = returnPath
        ? `${process.env.NEXT_PUBLIC_WEB_HOST}${
            returnPath.startsWith('/') ? '' : '/'
          }${returnPath}`
        : window.location.href;

      window.location.href = await this.gateway.exec({
        returnUrl,
      });
    }
  }
}

class ClientLogoutGateway {
  constructor(readonly gateway: LogoutGateway) {}

  async exec(returnPath?: string) {
    if (window) {
      const returnUrl = returnPath
        ? `${window.location.protocol}//${window.location.host}/${returnPath}`
        : window.location.href;

      window.location.href = await this.gateway.exec({
        returnUrl,
      });
    }
  }
}

class ClientRefreshTokenGateway {
  constructor(
    readonly HttpClient: HttpClient,
    readonly reduxDispatcher?: Dispatch<AnyAction>,
  ) {}

  async exec() {
    const response = (await this.HttpClient.request({
      method: 'POST',
      url: '/api/auth/refresh-token',
    })) as RefreshTokenResponse;

    if (response.code === '200' && response?.jwtToken) {
      const jwtToken = response.jwtToken;
      if (this.reduxDispatcher) {
        this.reduxDispatcher(successLogin(jwtToken));
      }
    } else {
      if (this.reduxDispatcher) {
        this.reduxDispatcher(failureLogin());
      }
    }

    return response;
  }
}

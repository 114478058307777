import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../store';
import { HYDRATE } from 'next-redux-wrapper';
import _ from 'lodash';
import {
  GoodsDeliveryInfo,
  GoodsDepartmentType,
  GoodsOperationInfo,
  GoodsSearchInfo,
  MeshupGoodsDetailInfo,
} from '@store/goods';
import {
  GoodsBenefitInfo,
  PackageGoods,
  PackageGoodsSeriesKindCode,
} from '@store/benefit';
import {
  GoodsReviewStatus,
  GoodsDetailCategoryList,
  GoodsDetailImageList,
  GoodsAdditionInfo,
  GoodsBlindInfo,
  GoodsCurtainInfo,
  GoodsCustomPackageInfo,
  GoodsOptionInfo,
  GoodsGiftList,
  GoodsDetailButton,
  GoodsDetailPlanInfo,
  GoodsDetailConsultInfo,
  GoodsView3D,
  GoodsPackageSeriesInfo,
  GoodsOrderSlot,
  GoodsCustomInfo,
} from '@/types';
import { seekOptionByPath } from '@/utils/goods-option';

export type OrderFlag = 'BASIC' | 'ADD' | 'PACKAGE';

export interface StoredGoods {
  // default-info API
  gdsNo: number;
  gdsNm?: string;
  brandNo?: number;
  brandNm?: string;
  images?: GoodsDetailImageList;
  badgeImageUrl?: string;
  categories?: GoodsDetailCategoryList;
  departmentType?: GoodsDepartmentType;
  reviewStatus?: GoodsReviewStatus;
  delivery?: GoodsDeliveryInfo;
  gifts?: GoodsGiftList;
  notiOfStockYn?: boolean;
  hasBeforeBuyInfo?: boolean;
  hasDeliveryGuide?: boolean;
  hasDisplayShop?: boolean;
  buttons?: GoodsDetailButton[];
  planInfo?: GoodsDetailPlanInfo;
  consultInfo?: GoodsDetailConsultInfo;
  view3D?: GoodsView3D[];
  couponPack?: {
    linkUrl: string;
    title: string;
    isEnd: boolean;
  };
  policyInfo?: GoodsOperationInfo;
  metaInfo?: GoodsSearchInfo;
  // option API
  optionInfo?: GoodsOptionInfo;
  additionInfo?: GoodsAdditionInfo;
  blindInfo?: GoodsBlindInfo;
  curtainInfo?: GoodsCurtainInfo;
  customPackageInfo?: GoodsCustomPackageInfo;
  seriesKindCd?: PackageGoodsSeriesKindCode;

  // benefit API
  benefitInfo?: Omit<GoodsBenefitInfo, 'packages'>;
  seriesInfo?: GoodsPackageSeriesInfo;
  packageInfo?: PackageGoods; // 본품에 대한 package 정보

  // Q&A (ask) API
  askCount?: number;

  // home-idea API
  homeIdeaCount?: number;

  // photo-evaluation API
  photoEvalCount?: number;

  // custom-goods API
  customInfo?: GoodsCustomInfo;

  // goods-info API
  detailInfo?: MeshupGoodsDetailInfo;

  custDownloadYn?: boolean;
  showDownloadedNotiYn?: boolean;

  slots?: GoodsOrderSlot[];
}

export interface GoodsDetailState {
  storedGoods: {
    [key: number]: StoredGoods;
  };
  isHFSIframe: boolean;
  order: {
    slots: GoodsOrderSlot[];
  };
  packageOrder: {
    isPackageOrderChange: number;
    slots: GoodsOrderSlot[];
  };
}

const initialState: GoodsDetailState = {
  storedGoods: {},
  isHFSIframe: false,
  order: {
    slots: [],
  },
  packageOrder: {
    isPackageOrderChange: 0,
    slots: [],
  },
};

export const goodsDetailSlice = createSlice({
  name: 'goodsDetail',
  initialState,
  reducers: {
    storeGoods: (state, action: PayloadAction<StoredGoods>) => {
      const storedGoods = state.storedGoods[action.payload.gdsNo];

      if (storedGoods) {
        state.storedGoods[action.payload.gdsNo] = {
          ...storedGoods,
          ...action.payload,
        };
      } else {
        state.storedGoods = {
          ...state.storedGoods,
          [action.payload.gdsNo]: {
            ...action.payload,
          },
        };
      }
    },
    setIsHFSIframe: (state, action: PayloadAction<boolean>) => {
      state.isHFSIframe = action.payload;
    },
    addSlot: (state, action: PayloadAction<GoodsOrderSlot>) => {
      const slot = action.payload;
      const slots = [...state.order.slots, slot];

      state.order.slots = _.sortBy(slots, ({ type }) => {
        switch (type) {
          case 'stored':
            return 1;
          case 'addition':
            return 2;
          default:
            return 3;
        }
      });
    },
    // 0 으로 update 하면 삭제됨
    setSlotAmount: (
      state,
      action: PayloadAction<{
        index: number;
        amount: number;
        update?: boolean;
      }>,
    ) => {
      const slots = [...state.order.slots];
      const index = action.payload.index;
      const amount = action.payload.amount;

      if (index < slots.length && index >= 0) {
        if (action.payload.update) {
          slots[index].amount = amount;
        } else {
          slots[index].amount += amount;
        }

        if (slots[index].amount < 1) {
          slots.splice(index, 1);
        }

        state.order.slots = slots;
      }
    },
    flushSlot: (state) => {
      state.order.slots = [];
    },

    // 패키지 주문의 경우, 본품 상품 정보를 가공해서 사용해야 해서, 저장소 분리하여 별도 사용
    addPackageSlot: (state, action: PayloadAction<GoodsOrderSlot[]>) => {
      const slots = [...action.payload].map((slot, idx) => {
        const parentGdsNo =
          slot.parentGdsNo && slot.parentGdsNo > 0
            ? slot.parentGdsNo
            : slot.goods.gdsNo;

        let sortedOrder;

        if ('seriesKindCd' in slot.goods && slot.goods.seriesKindCd !== null) {
          if (slot.sortedOrder) {
            sortedOrder = slot.sortedOrder;
          } else {
            if (slot.goods.seriesKindCd === 'M1') {
              sortedOrder = 1;
            } else if (slot.goods.seriesKindCd === 'S1') {
              sortedOrder = 3;
            } else if (slot.goods.seriesKindCd === 'M1S1') {
              if (slot.type === 'stored') {
                sortedOrder = 1;
              } else if (
                slot.goods.gdsNo === parentGdsNo &&
                state.order.slots.length - 1 < idx
              ) {
                sortedOrder = 3;
              } else {
                sortedOrder = 1;
              }
            }
          }
        } else if (slot.type === 'addition') {
          sortedOrder = 2;
        } else {
          sortedOrder = 4;
        }

        return {
          ...slot,
          sortedOrder,
        };
      });

      state.packageOrder = {
        ...state.packageOrder,
        slots: _.sortBy(slots, ({ sortedOrder }) => sortedOrder),
      };
    },
    // 0 으로 update 하면 삭제됨
    setPackageSlotAmount: (
      state,
      action: PayloadAction<{
        index: number;
        amount: number;
        update?: boolean;
      }>,
    ) => {
      const slots = [...state.packageOrder.slots];
      const index = action.payload.index;
      const amount = action.payload.amount;

      if (index < slots.length && index >= 0) {
        if (action.payload.update) {
          slots[index].amount = amount;
        } else {
          slots[index].amount += amount;
        }

        if (slots[index].amount < 1) {
          slots.splice(index, 1);
        }

        state.packageOrder.slots = slots;
        state.packageOrder.isPackageOrderChange++;
      }
    },
    refreshPackageSlot: (state, action: PayloadAction<GoodsOrderSlot[]>) => {
      state.packageOrder.slots = [...action.payload];
    },
    flushPackageSlot: (state) => {
      state.packageOrder.slots = [];
    },
    copyOrderSlotToPackageSlot: (
      state,
      action: PayloadAction<
        PackageGoods & {
          slots?: GoodsOrderSlot[];
          custDownloadYn?: boolean;
          showDownloadedNotiYn?: boolean;
        }
      >,
    ) => {
      if (action.payload.custDownloadYn !== undefined) {
        const storedGoods = state.storedGoods[action.payload.gdsNo];

        state.storedGoods[action.payload.gdsNo] = {
          ...storedGoods,
          custDownloadYn: action.payload.custDownloadYn,
          showDownloadedNotiYn: action.payload.showDownloadedNotiYn,
        };
      }
      const clones = [...(action.payload.slots || [])].map((slot, idx) => {
        const parentGdsNo =
          slot.parentGdsNo && slot.parentGdsNo > 0
            ? slot.parentGdsNo
            : slot.goods.gdsNo;

        let sortedOrder;

        if ('seriesKindCd' in slot.goods && slot.goods.seriesKindCd !== null) {
          if (slot.goods.seriesKindCd === 'M1') {
            sortedOrder = 1;
          } else if (slot.goods.seriesKindCd === 'S1') {
            sortedOrder = 3;
          } else if (slot.goods.seriesKindCd === 'M1S1') {
            if (slot.type === 'stored') {
              sortedOrder = 1;
            } else if (
              slot.goods.gdsNo === parentGdsNo &&
              state.order.slots.length - 1 < idx
            ) {
              sortedOrder = 3;
            } else {
              sortedOrder = 1;
            }
          }
        } else if (slot.type === 'addition') {
          sortedOrder = 2;
        } else {
          sortedOrder = 4;
        }

        return {
          ...slot,
          type: slot.type === 'stored' ? 'package' : slot.type,
          goods:
            slot.type === 'stored'
              ? {
                  ...action.payload,
                  seriesKindCd: (slot.goods as PackageGoods).seriesKindCd,
                }
              : slot.goods,
          parentGdsNo,
          sortedOrder,
        };
      });

      state.packageOrder = {
        ...state.packageOrder,
        slots: _.sortBy(clones, ({ sortedOrder }) => sortedOrder),
      };
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      return {
        ...state,
        ...action.payload.goodsDetail,
      };
    },
  },
});

export const {
  storeGoods,
  setIsHFSIframe,
  addSlot,
  setSlotAmount,
  flushSlot,
  addPackageSlot,
  setPackageSlotAmount,
  refreshPackageSlot,
  flushPackageSlot,
  copyOrderSlotToPackageSlot,
} = goodsDetailSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectStoredGoods = (state: RootState, gdsNo: number) =>
  state.goodsDetail.storedGoods[gdsNo];

export const selectIsHFSIframe = (state: RootState) =>
  state.goodsDetail.isHFSIframe;

export const selectGoodsDetailInfo = (state: RootState, gdsNo: number) => {
  const storedGoods = selectStoredGoods(state, gdsNo);
  if (storedGoods && storedGoods.detailInfo) {
    return storedGoods.detailInfo;
  }
};

export const selectGoodsOptionByPath = (
  state: RootState,
  gdsNo: number,
  path: number[],
) => {
  const storedGoods = selectStoredGoods(state, gdsNo);
  if (storedGoods && storedGoods.optionInfo) {
    return seekOptionByPath(storedGoods.optionInfo.optionTree, path);
  }
};
export const selectGoodsOptionTree = (state: RootState, gdsNo: number) => {
  const storedGoods = selectStoredGoods(state, gdsNo);
  if (storedGoods && storedGoods.optionInfo) {
    return storedGoods.optionInfo.optionTree;
  }
};
export const selectGoodsOptionList = (state: RootState, gdsNo: number) => {
  const storedGoods = selectStoredGoods(state, gdsNo);
  if (storedGoods && storedGoods.optionInfo) {
    return storedGoods.optionInfo.optionList;
  }
};
export const selectOrder = (state: RootState) => state.goodsDetail.order;
export const selectPackageOrder = (state: RootState) =>
  state.goodsDetail.packageOrder;

export default goodsDetailSlice;

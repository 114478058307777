import { useCallback } from 'react';
import { useCopyToClipboard } from 'react-use';

const useClipboard = () => {
  const [{ error }, copyToClipboard] = useCopyToClipboard();

  const handleCopy = useCallback(
    (text: string) => {
      copyToClipboard(text);
      return error;
    },
    [copyToClipboard, error],
  );

  return { handleCopy };
};

export default useClipboard;

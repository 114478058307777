import { AnyAction, Dispatch } from 'redux';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';
import querystring from 'query-string';
import NCookieStorage from './ncookie';
import { storePcid } from '@/redux/reducers/tracking';
import { addDays } from 'date-fns';
import { AuthState } from '@/redux/reducers/auth';

class TrackingService {
  constructor(
    private readonly storage: NCookieStorage,
    private readonly dispatch?: Dispatch<AnyAction>,
    private readonly rootState?: any,
  ) {}

  async initPcid() {
    const pcid = await this.storage.get('PCID');
    let pcid2 = await this.storage.get('PCID2');

    if (!_.isString(pcid2)) {
      if (!_.isString(pcid)) {
        pcid2 = uuid();
      } else {
        pcid2 = pcid;
      }
      this.storage.set('PCID2', pcid2, {
        domain: `${process.env.NEXT_PUBLIC_COOKIE_DOMAIN}`,
        path: '/',
        expires: addDays(new Date(), 403),
        httpOnly: false,
      });
    }

    if (this.dispatch) {
      this.dispatch(storePcid({ pcid: pcid || null, pcid2: pcid2 || null }));
    }
  }

  async initLoginCookieMemNo() {
    const { auth } = this.rootState as { auth: AuthState };
    if (auth?.authInfo?.intMbsCustNo) {
      const newValue = Buffer.from(auth.authInfo.intMbsCustNo).toString(
        'base64',
      );
      const loginCookieMemNo = await this.storage.get('loginCookieMemNo');
      if (!_.isString(loginCookieMemNo) || newValue !== loginCookieMemNo) {
        this.storage.set('loginCookieMemNo', newValue, {
          path: '/',
          httpOnly: false,
        });
      }
    } else {
      this.storage.remove('loginCookieMemNo');
    }
  }

  async initLoginCookieId() {
    const { auth } = this.rootState as { auth: AuthState };
    if (auth?.authInfo?.custID) {
      const newValue = Buffer.from(auth.authInfo.custID).toString('base64');
      const loginCookieId = await this.storage.get('loginCookieId');
      if (!_.isString(loginCookieId) || newValue !== loginCookieId) {
        this.storage.set('loginCookieId', newValue, {
          path: '/',
          httpOnly: false,
        });
      }
    } else {
      this.storage.remove('loginCookieId');
    }
  }

  async init() {
    try {
      await this.initPcid();
    } catch (error) {}
    try {
      await this.initLoginCookieMemNo();
    } catch (error) {}
    try {
      await this.initLoginCookieId();
    } catch (error) {}
  }

  static combineQueryParam = (url: string, query: {}) => {
    const parts = url.split('?');

    if (parts.length <= 1) {
      parts.push(querystring.stringify(query));
    } else {
      let part = _.trim(parts[1]);

      if (part.length > 0 && !_.endsWith(part, '&')) {
        part = `${part}&`;
      }

      parts[1] = `${part}${querystring.stringify(query)}`;
    }

    return parts.join('?');
  };

  static combineWiseLogParam = (url: string, wiseLogParam?: string | null) => {
    if (wiseLogParam && wiseLogParam.length > 0) {
      return this.combineQueryParam(url, { wlp: wiseLogParam });
    }

    return url;
  };

  static retainQueryParam = (url: string, query: {}) => {
    if (typeof window !== 'undefined') {
      try {
        const combinedQuery = {
          ...querystring.parse(window.location.search),
          ...query,
        };

        return this.combineQueryParam(url, combinedQuery);
      } catch (error) {}
    }

    return url;
  };

  static tagGTM = (
    label: string,
    extra?: { [key: string]: string | number | boolean | undefined },
  ): { [key: string]: string | number | boolean } => {
    const attributes = extra
      ? _.reduce(
          _.mapKeys(extra, (_$, key) =>
            _.startsWith(key, 'data-gtm-tracking-')
              ? key
              : `data-gtm-tracking-${key}`,
          ),
          (result, value, key) => {
            if (value) {
              _.set(result, key, value);
            }
            return result;
          },
          {},
        )
      : {};
    return {
      'data-gtm-tracking-section': 'store',
      'data-gtm-tracking': label,
      ...attributes,
    };
  };
}

export default TrackingService;

import { StorageDispatcher } from '@store/base';
import { NextApiRequest, NextApiResponse } from 'next';
import { setCookie, parseCookies, destroyCookie } from 'nookies';

class NCookieStorage implements StorageDispatcher {
  constructor(
    private readonly req: NextApiRequest,
    private readonly res: NextApiResponse,
  ) {}

  set<Value>(key: string, value: Value, options?: any): void {
    setCookie({ res: this.res }, key, value as unknown as string, {
      path: '/',
      httpOnly: true,
      sameSite: 'lax',
      secure: false,
      domain: '',
      ...options,
    });
  }

  get<Value = string>(key: string, options?: any): Promise<Value> {
    return new Promise<Value>((resolve) => {
      resolve(
        parseCookies({ req: this.req }, { ...options })[
          key
        ] as unknown as Value,
      );
    });
  }

  remove(key: string, options?: any): void {
    destroyCookie({ res: this.res }, key, {
      maxAge: -1,
      domain: '',
      path: '/',
      ...options,
    });
  }
}

export default NCookieStorage;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../store';

export interface GnbState {
  isHeaderShow: boolean;
}

const initialState: GnbState = {
  isHeaderShow: false,
};

export const gnbSlice = createSlice({
  name: 'gnb',
  initialState,
  reducers: {
    setIsHeaderShow: (state, action: PayloadAction<boolean>) => {
      state.isHeaderShow = action.payload;
    },
  },
});

export const { setIsHeaderShow } = gnbSlice.actions;

export const selectGnb = (state: RootState) => state.gnb;

export default gnbSlice;

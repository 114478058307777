import { HACKLE_DEVICE_COOKIE_NAME } from '@/constants';
import NCookieStorage from '@/services/ncookie';
import { hackleClient } from '@pages/_app';
import { RefreshTokenResponse } from '@store/auth';
import {
  GetServerSidePropsContext,
  NextApiRequest,
  NextApiResponse,
  PreviewData,
} from 'next';
import { ParsedUrlQuery } from 'querystring';
export function getDocumentCookie(name: string) {
  const value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return value ? value[2] : null;
}

export function getClientUser() {
  const deviceId = getDocumentCookie(HACKLE_DEVICE_COOKIE_NAME) || '';
  return {
    id: deviceId,
    deviceId,
    properties: {
      deviceChannel: 'WEB',
    },
  };
}

export async function getServerVariation(
  context: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>,
  experimentKey: number,
) {
  const nCookiesStorage = new NCookieStorage(
    context.req as NextApiRequest,
    context.res as NextApiResponse<RefreshTokenResponse>,
  );

  const hackleCookie = await nCookiesStorage.get(HACKLE_DEVICE_COOKIE_NAME);

  const deviceId = hackleCookie;

  await hackleClient.onInitialized();

  const { variation } = hackleClient.variationDetail(experimentKey, {
    id: deviceId,
    deviceId,
    properties: {
      deviceChannel: 'WEB',
    },
    // userId: "로그인 한 사용자 ID가 있을 때만" (PCID) // 특정 사용자에게만 A/B 테스트가 필요한 경우
  });

  return { variation, deviceId };
}

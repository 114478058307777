import TrackingService from '@/services/tracking';
import { MAX_Z_INDEX, theme } from '@/styles';
import Image from '@/ui/Image';
import { getAsset } from '@/utils';
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import ContentsButton from '../ContentsButton';

export interface ModalProps {
  title?: string;
  text?: string;
  cancelText?: string;
  confirmText?: string;
  subtext?: string;
  type?: 'confirm' | 'alert';
  isOpen: boolean;
  hasIcon?: boolean;
  closeText?: string;
  onCancel?: () => void;
  onClose: (e: boolean) => void;
  onConfirm?: () => void;
  subTextAlign?: 'left' | 'center';
  handleBeforeClose?: () => void;
  gtmName?: string;
  gtmValue?: Record<string, any>;
}

const ModalCloseIcon = '/assets/icons/ic_close_32.svg';

const Modal = ({
  hasIcon = true,
  gtmName = '',
  gtmValue,
  ...props
}: ModalProps) => {
  const { onConfirm, onClose, handleBeforeClose } = props;

  const handleConfirm = useCallback(() => {
    onConfirm && onConfirm();
    onClose(false);
  }, [onClose, onConfirm]);

  useEffect(() => {
    if (!props.isOpen) return;
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [props.isOpen]);

  return (
    <DimmedBox isOpen={props.isOpen} {...TrackingService.tagGTM(`${gtmName}`)}>
      <ModalBox>
        {hasIcon && (
          <IconBox
            onClick={() => {
              if (handleBeforeClose) {
                handleBeforeClose();
              }
              props.onClose(false);
            }}
            {...TrackingService.tagGTM(
              gtmName ? `${gtmName}_close_button` : 'close_button',
              gtmValue,
            )}
          >
            <Image
              src={getAsset(ModalCloseIcon)}
              alt="modalClose"
              width="32px"
              height={'32px'}
            />
          </IconBox>
        )}
        {props.title && <TitleBox>{props.title}</TitleBox>}
        {props.text && <TextBox isCenter={!props.title}>{props.text}</TextBox>}
        {props.subtext && (
          <SubtextBox isCenter={!props.title} subTextAlign={props.subTextAlign}>
            {props.subtext}
          </SubtextBox>
        )}
        <ModalBtnLayout>
          {props.type === 'confirm' ? (
            <>
              <ContentsButton
                size="regular"
                width="100%"
                emphasis="medium"
                onClick={() => {
                  props.onCancel && props.onCancel();
                  props.onClose(false);
                }}
                {...TrackingService.tagGTM(
                  gtmName ? `${gtmName}_cancel_button` : 'cancel_button',
                  gtmValue,
                )}
              >
                {props.cancelText || `취소`}
              </ContentsButton>
              <ContentsButton
                size="regular"
                width="100%"
                emphasis="high"
                onClick={handleConfirm}
                {...TrackingService.tagGTM(
                  gtmName ? `${gtmName}_confirm_button` : 'confirm_button',
                  gtmValue,
                )}
              >
                {props.confirmText || '확인'}
              </ContentsButton>
            </>
          ) : (
            <ContentsButton
              size="regular"
              width="100%"
              emphasis="high"
              onClick={handleConfirm}
              {...TrackingService.tagGTM(
                gtmName ? `${gtmName}_confirm_button` : 'confirm_button',
                gtmValue,
              )}
            >
              {props.confirmText ? props.confirmText : '확인'}
            </ContentsButton>
          )}
        </ModalBtnLayout>
      </ModalBox>
    </DimmedBox>
  );
};

export default Modal;

const DimmedBox = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  position: fixed;
  z-index: ${MAX_Z_INDEX};
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.accent_colors.dimmed[1]};
`;

const ModalBox = styled.div`
  padding: 40px;
  width: 370px;
  border-radius: 2px;
  background-color: ${theme.colors.neutral_colors.white};
  position: relative;
`;

const ModalBtnLayout = styled.div`
  display: flex;
  width: 100%;
  margin-top: 32px;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
`;

const TitleBox = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 24px;
  text-align: center;
  color: ${theme.colors.accent_colors.primary.default};
  ${theme.typography.subtitle4};
  white-space: pre-line;
`;

const TextBox = styled.div<{ isCenter: boolean }>`
  display: block;
  color: ${theme.colors.accent_colors.primary.default};
  ${theme.typography.body1};
  text-align: ${({ isCenter }) => (isCenter ? 'center' : 'start')};
  white-space: pre-line;
`;

const SubtextBox = styled.div<{
  isCenter: boolean;
  subTextAlign?: 'left' | 'center';
}>`
  display: block;
  color: ${theme.colors.neutral_colors.gray[600]};
  margin-top: 12px;
  ${theme.typography.body2};
  text-align: ${({ subTextAlign }) => subTextAlign};
  white-space: pre-line;
`;

const IconBox = styled.div`
  position: absolute;
  cursor: pointer;
  right: 16px;
  top: 16px;
`;

const DefaultSeo = {
  title: '한샘몰',
  additionalLinkTags: [
    {
      rel: 'shortcut icon',
      href: `${process.env.NEXT_PUBLIC_MWEB_ASSET_HOST}/favicon.ico`,
    },
  ],
  additionalMetaTags: [
    {
      name: 'viewport',
      content:
        'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0',
    },
    {
      name: 'apple-mobile-web-app-title',
      content: 'HANSSEM',
    },
    {
      name: 'format-detection',
      content: 'telephone=no',
    },
    {
      name: 'referrer',
      content: 'unsafe-url',
    },
  ],
  openGraph: {
    type: 'website',
    site_name: 'hanssem',
    url: `${process.env.NEXT_PUBLIC_WEB_HOST}`,
    title: '한샘몰',
    description:
      '토탈 홈 인테리어 쇼핑몰, 한샘몰 쉽고 편리한 온라인 쇼핑, 안방까지 찾아가는 한샘만의 올인원 서비스, 한샘몰만의 특별한 가격혜택 및 콘텐츠/서비스를 제공합니다.',
    images: [
      {
        url: `${process.env.NEXT_PUBLIC_ASSET_HOST}/web/seo/og_bi.jpeg`,
        width: 800,
        height: 800,
      },
    ],
  },
};

export default DefaultSeo;

import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { Box, BoxProps } from '@design-system/pc';
import _ from 'lodash';
import styled from 'styled-components';
import classnames from 'classnames';
import { createPortal } from 'react-dom';
import { DRAWER_Z_INDEX, HEADER_Z_INDEX, MAX_Z_INDEX } from '@/styles';

export interface Drawer {
  id: string;
  visible: boolean;
}

const DrawerPopUpContext = createContext({
  drawers: [],
  attachDrawer: () => {},
  detachDrawer: () => {},
  isOpen: (_) => false,
  handleOpen: (_) => {},
  handleClose: (_) => {},
} as {
  drawers: Drawer[];
  attachDrawer: (drawer: Drawer) => void;
  detachDrawer: (id: string) => void;
  isOpen: (id: string) => boolean;
  handleOpen: (id: string) => void;
  handleClose: (id: string) => void;
});

export const useDrawerPopUp = () => {
  const context = useContext(DrawerPopUpContext);

  if (context === undefined) {
    throw new Error('useDrawerPopUp must be used within a DrawerPopUpContext');
  }

  return context;
};

export interface DrawerPopUpProviderProps {
  children: ReactNode | ReactNode[];
}

const DrawerPopUpProvider = ({ children }: DrawerPopUpProviderProps) => {
  const [drawers, setDrawers] = useState<Drawer[]>([]);

  const attachDrawer = useCallback((drawer: Drawer) => {
    setDrawers((stack) => [drawer, ...stack]);
  }, []);
  const detachDrawer = useCallback((id: string) => {
    setDrawers((stack) => {
      const newValues = [...stack];
      const index = _.findIndex(newValues, { id });
      if (index >= 0) {
        newValues.splice(index, 1);
      }
      return newValues;
    });
  }, []);

  const isOpen = useCallback(
    (id: string) => {
      const drawer = _.find(drawers, { id });
      return drawer ? drawer.visible : false;
    },
    [drawers],
  );
  const handleClose = useCallback(
    (id: string) => {
      const newStack = [...drawers];
      const index = _.findIndex(newStack, { id });
      if (index >= 0) {
        setDrawers(() => {
          const newValues = [...newStack];
          newValues[index].visible = false;
          return newValues;
        });
      }
    },
    [drawers],
  );
  const handleOpen = useCallback(
    (id: string) => {
      const newStack = [...drawers];
      const index = _.findIndex(newStack, { id });
      if (index >= 0) {
        setDrawers(() => {
          const newValues = [...newStack];
          newValues[index].visible = true;
          return newValues;
        });
      }
    },
    [drawers],
  );

  const openDim = useCallback(() => {
    if (typeof window !== 'undefined') {
      window.document.getElementById('DrawerDim')!.style.display = 'block';
    }
  }, []);
  const closeDim = useCallback(() => {
    if (typeof window !== 'undefined') {
      window.document.getElementById('DrawerDim')!.style.display = 'none';
    }
  }, []);
  useEffect(() => {
    const hasOpen = drawers.reduce(
      (result, drawer) => result || drawer.visible,
      false,
    );
    if (hasOpen) {
      openDim();
    } else {
      closeDim();
    }
  });

  const router = useRouter();
  const closeAllWhenRouting = useCallback(() => {
    setDrawers((stack) => {
      const newValues = stack.map((item) => {
        item.visible = false;
        return { ...item };
      });
      return [...newValues];
    });
  }, []);
  useEffect(() => {
    router.events.on('routeChangeComplete', closeAllWhenRouting);
    return () => {
      router.events.off('routeChangeComplete', closeAllWhenRouting);
    };
  }, [closeAllWhenRouting, router.events]);

  return (
    <DrawerPopUpContext.Provider
      value={{
        drawers,
        attachDrawer,
        detachDrawer,
        isOpen,
        handleOpen,
        handleClose,
      }}
    >
      {children}
      <DrawerPopUpContext.Consumer>
        {(_) => <div id="drawer-popup-portal"></div>}
      </DrawerPopUpContext.Consumer>
      <DrawerWrapper
        id="DrawerDim"
        zIndex={HEADER_Z_INDEX + 1}
        backgroundColor="dimmed.dimmed_2"
        display="none"
      />
    </DrawerPopUpContext.Provider>
  );
};

export default DrawerPopUpProvider;

export interface DrawerPopUpProps extends Pick<Drawer, 'id'> {
  depth?: 'MAX' | number;
  children: (args: {
    isOpen: boolean;
    handleClose: (id: string) => void;
    drawerRef: HTMLElement | null;
  }) => ReactNode | ReactNode[];
}

export const DrawerPopUp = ({
  id,
  depth,
  children,
  ...otherProps
}: DrawerPopUpProps & Omit<BoxProps, 'children'>) => {
  const [mounted, setMounted] = useState(false);
  const [drawerRef, setDrawerRef] = useState<HTMLDivElement | null>(null);

  const { drawers, attachDrawer, detachDrawer, isOpen, handleClose } =
    useDrawerPopUp();

  useEffect(() => {
    setMounted(true);
    attachDrawer({
      id,
      visible: false,
    });
    return () => {
      setMounted(false);
      detachDrawer(id);
    };
  }, [attachDrawer, detachDrawer, id]);

  const portalEl =
    typeof window !== 'undefined' &&
    document.querySelector('#drawer-popup-portal');

  const zIndex = useMemo(() => {
    let value = depth;
    value = value || drawers.length + DRAWER_Z_INDEX;
    value = value === 'MAX' ? MAX_Z_INDEX : value;
    return value;
  }, [depth, drawers.length]);

  return mounted && portalEl && children ? (
    createPortal(
      <DrawerWrapper zIndex={zIndex} display={isOpen(id) ? 'block' : 'none'}>
        <DrawerContainer
          ref={setDrawerRef}
          className={classnames({
            open: isOpen(id),
          })}
          id={id}
          {...(otherProps as unknown as any)}
        >
          {isOpen(id) &&
            children({ handleClose, isOpen: isOpen(id), drawerRef })}
        </DrawerContainer>
      </DrawerWrapper>,
      portalEl,
    )
  ) : (
    <></>
  );
};

const DrawerWrapper = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const DrawerContainer = styled(Box)`
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.1s ease-out;
  height: 100dvh;
  transform: scaleZ(0);
  top: 0;
  right: -100%;
  bottom: 0;
  width: max-content;
  &.open {
    transform: inherit;
    top: 0;
    right: 0;
    bottom: 0;
  }
  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overscroll-behavior: contain;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

/* eslint-disable no-use-before-define */
import React from 'react';
import { configureStore, Store } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import createSagaMiddleware, { Task } from 'redux-saga';
import rootSaga from './sagas';
import rootReducer from './reducers';
import { axios } from '@/di-container/axios';

export const makeStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: rootReducer,
    devTools: true,
    middleware: [sagaMiddleware],
  });

  (store as SagaStore).sagaTask = sagaMiddleware.run(rootSaga);

  axios.syncStore(store);

  return store;
};

export type RootStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<RootStore['getState']>;
export type AppDispatch = RootStore['dispatch'];
export interface SagaStore extends Store<RootState> {
  sagaTask?: Task;
}

export const wrapper = createWrapper(makeStore, {
  debug: false,
});

export const rootAppWrapper = (App: React.ComponentType<any>) => {
  return wrapper.withRedux(App);
};

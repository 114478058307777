import { axios, refreshAxios } from './../../axios';
import {
  failureLogin,
  successGuest,
  successLogin,
} from '@/redux/reducers/auth';
import NCookieStorage from '@/services/ncookie';
import {
  Token,
  LoginGateway,
  LogoutGateway,
  RefreshTokenGateway,
  RefreshTokenRequest,
  GetGuestInfoGateway,
} from '@store/auth';
import { NextApiRequest, NextApiResponse } from 'next';
import { AnyAction, Dispatch } from 'redux';

export const authGateway = (
  req: NextApiRequest,
  res: NextApiResponse,
  reduxDispatcher?: Dispatch<AnyAction>,
) => {
  const storage = new NCookieStorage(req, res);

  return {
    login: new ServerLoginGateway(
      req,
      new LoginGateway(
        `${process.env.NEXT_PUBLIC_HANSSEMMALL_WEB_HOST}/customer/mallLoginMain.do`,
      ),
    ),
    logout: new ServerLogoutGateway(req, new LogoutGateway()),
    syncToken: new SyncTokenGateway(storage, reduxDispatcher),
    syncGuest: new SyncGuestGateway(
      storage,
      new GetGuestInfoGateway(axios),
      reduxDispatcher,
    ),
    refreshToken: new ServerRefreshTokenGateway(
      storage,
      new RefreshTokenGateway(refreshAxios),
      reduxDispatcher,
    ),
  };
};

class ServerLoginGateway {
  constructor(readonly req: NextApiRequest, readonly gateway: LoginGateway) {}

  async exec(returnPath?: string) {
    const returnUrl = returnPath
      ? `${process.env.NEXT_PUBLIC_WEB_HOST}/${returnPath}`
      : `${process.env.NEXT_PUBLIC_WEB_HOST}/${this.req.url}`;

    return await this.gateway.exec({
      returnUrl,
    });
  }
}

class ServerLogoutGateway {
  constructor(readonly req: NextApiRequest, readonly gateway: LogoutGateway) {}

  async exec(returnPath?: string) {
    const returnUrl = returnPath
      ? `${process.env.NEXT_PUBLIC_WEB_HOST}/${returnPath}`
      : `${process.env.NEXT_PUBLIC_WEB_HOST}/${this.req.url}`;

    return await this.gateway.exec({
      returnUrl,
    });
  }
}

class SyncTokenGateway {
  constructor(
    readonly storage: NCookieStorage,
    readonly reduxDispatcher?: Dispatch<AnyAction>,
  ) {}

  async exec(): Promise<Pick<Token, 'accessToken' | 'refreshToken'>> {
    const accessToken = await this.storage.get(
      `${process.env.NEXT_PUBLIC_ACCESS_TOKEN_NAME}`,
    );
    const refreshToken = await this.storage.get(
      `${process.env.NEXT_PUBLIC_REFRESH_TOKEN_NAME}`,
    );
    if (this.reduxDispatcher) {
      if (accessToken && refreshToken) {
        this.reduxDispatcher(successLogin({ accessToken, refreshToken }));
      } else {
        this.reduxDispatcher(failureLogin());
      }
    }
    return { accessToken, refreshToken };
  }
}

class SyncGuestGateway {
  constructor(
    readonly storage: NCookieStorage,
    readonly gateway: GetGuestInfoGateway,
    readonly reduxDispatcher?: Dispatch<AnyAction>,
  ) {}

  async exec() {
    const accessToken = await this.storage.get(
      `${process.env.NEXT_PUBLIC_ACCESS_TOKEN_NAME}`,
    );
    const guestNo = await this.storage.get(
      `${process.env.NEXT_PUBLIC_GUEST_NO_NAME}`,
    );
    if (this.reduxDispatcher) {
      if (guestNo) {
        this.reduxDispatcher(successGuest({ custNo: guestNo }));
      } else {
        if (!accessToken) {
          const apiResponse = await this.gateway.exec();
          if (apiResponse.code === 200) {
            const { noLoginUserNo } = apiResponse.data!;

            this.reduxDispatcher(successGuest({ custNo: `${noLoginUserNo}` }));
            this.storage.set(
              `${process.env.NEXT_PUBLIC_GUEST_NO_NAME}`,
              noLoginUserNo,
              {
                domain: `${process.env.NEXT_PUBLIC_COOKIE_DOMAIN}`,
                path: '/',
                httpOnly: false,
              },
            );
          }
        }
      }
    }
  }
}

export class ServerRefreshTokenGateway {
  constructor(
    readonly storage: NCookieStorage,
    readonly gateway: RefreshTokenGateway,
    readonly reduxDispatcher?: Dispatch<AnyAction>,
  ) {}

  async exec(payload: RefreshTokenRequest) {
    const response = await this.gateway.exec(payload);

    if (response.code === '200' && response?.jwtToken) {
      const jwtToken = response.jwtToken;

      this.storage.set(
        `${process.env.NEXT_PUBLIC_ACCESS_TOKEN_NAME}`,
        jwtToken.accessToken,
        {
          domain: `${process.env.NEXT_PUBLIC_COOKIE_DOMAIN}`,
          path: '/',
        },
      );
      this.storage.set(
        `${process.env.NEXT_PUBLIC_REFRESH_TOKEN_NAME}`,
        jwtToken.refreshToken,
        {
          domain: `${process.env.NEXT_PUBLIC_COOKIE_DOMAIN}`,
          path: '/',
        },
      );
      if (this.reduxDispatcher) {
        this.reduxDispatcher(successLogin(jwtToken));
      }
    } else {
      this.storage.remove(`${process.env.NEXT_PUBLIC_ACCESS_TOKEN_NAME}`);
      this.storage.remove(`${process.env.NEXT_PUBLIC_REFRESH_TOKEN_NAME}`);
      if (this.reduxDispatcher) {
        this.reduxDispatcher(failureLogin());
      }
    }

    return response;
  }
}
